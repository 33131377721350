import Utils from "./Utils";
import { t } from 'i18next';
import axios from 'axios';

//DataManager, component with all API calls
function getOrganizer(idOrganizer) {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_ORGANIZERS + "/" + idOrganizer.toString();

    const options = {
        method: 'GET',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"organizer": response.data, "msgError": ""};
        }else{
            return {"organizer": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"organizer": null, "msgError": msgError};
    });

};

function getOrganizers(page) {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_ORGANIZERS + "?page=" + page.toString();

    const options = {
        method: 'GET',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"organizers": response.data, "msgError": ""};
        }else{
            return {"organizers": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"organizers": null, "msgError": msgError};
    });

};

function login() {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_LOGIN;

    const options = {
        method: 'POST',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        data: {"username": "bluenet", "password": "ciao", "organizer": "03984870612"}
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"login": response.data, "msgError": ""};
        }else{
            return {"login": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"login": null, "msgError": msgError};
    });


};

function verifyLogin(token) {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_VERIFY_LOGIN;

    const options = {
        method: 'POST',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        data: {"token": token}
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"verifylogin": response.data, "msgError": ""};
        }else{
            return {"verifylogin": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"verifylogin": null, "msgError": msgError};
    });


};

function getEvents(token, page) {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_GET_EVENTS + "?page=" + page.toString() + "&slim=true&limit=50";

    const options = {
        method: 'GET',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token,
        }
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"events": response.data, "msgError": ""};
        }else{
            return {"events": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"events": null, "msgError": msgError};
    });

};

function getReplicas(token, idEvent) {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_GET_EVENTS + "/" + idEvent;

    const options = {
        method: 'GET',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token,
        }
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"replicas": response.data, "msgError": ""};
        }else{
            return {"replicas": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"replicas": null, "msgError": msgError};
    });

};

function getItems(token, idReply) {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_GET_EVENTS + "/" + idReply + "/items";

    const options = {
        method: 'GET',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token,
        }
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"items": response.data, "msgError": ""};
        }else{
            return {"items": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"items": null, "msgError": msgError};
    });


};

function postOrder(token, arr, clientId, presale, hasMap) {

    const link = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_SUB + process.env.REACT_APP_URL_API_ORDERS;

    let data = {};
    if (hasMap){
        data = {"clientId": clientId, "disablePresaleFee": presale, ...arr}
    }else{
        data = {"disablePresaleFee": presale, ...arr}
    }

    const options = {
        method: 'POST',
        url: link,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token,
        },
        data: data
    };

    return axios.request(options).then(function (response) {
        if (typeof response.data !== 'undefined' && response.data !== null){
            return {"orders": response.data, "msgError": ""};
        }else{
            return {"orders": null, "msgError": t('msgErrorGeneral')};
        }
    }).catch(function (error) {
        let msgError = error.message;
        if (typeof error.response !== 'undefined' && error.response !== null){
            if (typeof error.response.data !== 'undefined' && error.response.data !== null){
                if (typeof error.response.data.message !== 'undefined' && error.response.data.message !== null && error.response.data.message.toString() !== ""){
                    msgError = error.response.data.message.toString();
                }else{
                    if (typeof error.response.data.error !== 'undefined' && error.response.data.error !== null && error.response.data.error.toString() !== ""){
                        msgError = error.response.data.error.toString();
                    }
                }
            }
        }
        return {"orders": null, "msgError": msgError};
    });


};

export default {login, verifyLogin, getEvents, getReplicas, getOrganizer, getOrganizers, getItems, postOrder}
