import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {Spinner, Row, Col, Container} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import {t} from "i18next";
import {useState} from "react";

// Elements
import ButtonWizard from "./ButtonWizard";
import Functions from "../../Functions";
import WidgetEvent from "../../elements/widget-event";
import DataManager from "../../DataManager";
import ListSector from "./ListSector";

//images
import imgPlaceholder from "../../../images/placeholderTicalo.png";
import UserClass from "../../LocalStorage/UserClass";


const SelectTicket = (props) => {

    const [err, setErr] = useState("");

    const [arrSeats, setArrSeats] = useState([]);
    const [items, setItems] = useState([]);
    const [loadItems, setLoadItems] = useState(false);

    React.useEffect(() => {
        let instance = UserClass.getInstance();
        let tokenUser = instance.getTokenUser();
        getItems(tokenUser, props.dataReply.id.toString())

        console.log(props)
        console.log("SelectTicket start")

    }, []);

    const actionContinue = (e) => {
        e.preventDefault();
        if (arrSeats.length > 0){
            setTimeout(() => {
                Functions.backToTop();
            }, 100);
            props.nextStep(arrSeats, []);
        }else{
            Functions.alertMsg(t("msgErrorSelectSeat"));
        }
    };

    /*const loginApp = () => {
        DataManager.login().then((result) => {
            if(typeof (result) !== 'undefined' && result !== null){
                if (typeof (result['login']) !== 'undefined' && result['login'] !== null) {
                    getItems(result['login'].token.toString(), props.dataReply.id.toString())
                } else {
                    let error = t("msgErrorGeneral");
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        error = result['msgError'];
                    }
                }
            }else{
                console.log("error login server")
            }
        });
    };*/

    const getItems = (token, id) => {
        setErr("");
        setLoadItems(false);
        DataManager.getItems(token, id).then((result) => {
            if(typeof (result) !== 'undefined' && result !== null){
                if (typeof (result['items']) !== 'undefined' && result['items'] !== null) {
                    let arr = result['items'].results;
                    let arrItems = [];
                    setLoadItems(true);
                    for (let i=0; i < arr.length; i++) {
                        let obj = arr[i];
                        for (let j=0; j < obj.items.length; j++) {
                            let objItem = obj.items[j];
                            if (typeof (objItem.salesChannels) !== 'undefined'  && objItem.salesChannels !== null && objItem.salesChannels.length > 0) {
                                let arr = objItem.salesChannels.find(s => s.toString() === "WEB");
                                if (typeof (arr) !== 'undefined' && arr !== null) {
                                    arrItems.push(objItem)
                                }
                            }
                        }
                    }
                    if(typeof (props.dataReply) !== 'undefined' && props.dataReply !== null){
                        if(typeof (props.dataReply.location) !== 'undefined' && props.dataReply.location !== null){
                            if(typeof (props.dataReply.location.seatingOrders) !== 'undefined' && props.dataReply.location.seatingOrders !== null && props.dataReply.location.seatingOrders.length > 0){
                                for (let z=0; z < arrItems.length; z++) {
                                    let objItem = arrItems[z];
                                    let arrType = props.dataReply.location.seatingOrders.filter(z => z.code.toString() === objItem.seatingOrder.toString());
                                    if (arrType.length > 0){
                                        objItem["nameCategory"] = arrType[0].name.toString();
                                    }
                                }
                            }
                        }
                    }
                    setItems(arrItems);
                } else {
                    let strErr = t("msgErrorGeneral");
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        strErr = result['msgError'];
                    }
                    setErr(strErr);
                    setLoadItems(true);
                }
            }else{
                setErr(t("msgErrorServer"));
                setLoadItems(true);
            }
        });
    };

    const getSeatsSelection = (arr) => {
        setArrSeats([]);
        setArrSeats(arr);
    };

    return(
        <>
            <>
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    closeOnClick
                    newestOnTop={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ "zIndex":1000000}}
                >
                </ToastContainer>
            </>
            <fieldset>
                <div className="page-content bg-white">
                    <Container className="container-map">
                        <Row>
                            <Col xl={2}>
                                <WidgetEvent {...props} />
                            </Col>
                            <Col xl={10}>
                                {
                                    items.length > 0 ?
                                        <ListSector {...props} items={items}
                                                    seatsSelection={getSeatsSelection}/>
                                        : !loadItems
                                            ? (
                                                <div>
                                                    <Spinner as="span"
                                                             size="sm"
                                                             role="status"
                                                             aria-hidden="true"
                                                             animation="border" className="d-flex align-items-center">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            )
                                            : err !== ""
                                                ? (
                                                    err
                                                )
                                                : null
                                }
                            </Col>
                        </Row>
                    </Container>
                    <ButtonWizard btnClicked={actionContinue} text={t("next")} />
                </div>

            </fieldset>
        </>
    );

}

export default withRouter(SelectTicket);
