import { Component } from 'react';
import moment from "moment";
import 'moment/locale/it';
import {toast} from "react-toastify";
import Utils from "./Utils";

//Functions, component with all generic functions
class Functions extends Component{

    static renderClassTimer = (minutes, seconds) =>{
        if (minutes >= 10){
            return "btn-group timer-background-green float-right";
        }else if (minutes >= 5 && minutes < 10){
            return "btn-group timer-background-yellow float-right";
        }else if (minutes > 0 && minutes < 5){
            return "btn-group timer-background-red float-right";
        }else if (minutes === 0 && seconds <= 59){
            if (minutes === 0 && seconds === 0) {
                return "btn-group timer-background float-right";
            }else{
                return "btn-group timer-background-red float-right";
            }
        }else{
            return "btn-group timer-background float-right";
        }
    };

    static alertMsg(msg){
        toast.error(msg, {
            theme: "colored",
            position: "bottom-center",
            autoClose: 3000
        });
    }

    static alertMsgSuccess(msg){
        toast.success(msg, {
            theme: "colored",
            position: "bottom-center",
            autoClose: 3000
        });
    }

    static convertDate (date){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        let dateLocal = date.toString();
        return moment(new Date(dateLocal))
            .utc().format('lll');
    }

    static currentDate (){
        return moment().format("YYYY-MM-DD");
    }

    static currentDatePrenotation (){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        return moment().format("DD-MM-YYYY");
    }

    static currentTimeByDate(date){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        let dateLocal = date.toString();
        let m = moment(dateLocal).toString();
        const dateTrip = new Date(m);
        //const currentTime = dateTrip.toLocaleTimeString(langLocale).replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        let t = dateTrip.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false});
        t = t.replace(/\u200E/g, '');
        t = t.replace(/^([^\d]*\d{1,2}:\d{1,2}):\d{1,2}([^\d]*)$/, '$1$2');
        return t.toString();
    }

    static convertFullDate(dateString){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        return moment(new Date(dateString))
            .utc().format('LLLL');
    }

    static convertDateToString(date, format){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        let dateLocal = date.toString();
        return moment(dateLocal).format(format).toString();
    }

    static convertDateExtendedToString(dateString){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        return moment(new Date(dateString))
            .utc().format('LLL');
    }

    static convertDateMinumString(dateString){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        return moment(new Date(dateString))
            .utc().format('L');
    }

    static convertDateToDay(dateString){
        let langLocale = this.getLocale();
        moment.locale(langLocale);
        let m = moment(new Date(dateString))
            .utc().format('dddd');
        return m.slice(0,1).toUpperCase() + m.slice(1, m.length);
    }

    static currentTime(){
        //let langLocale = this.getLocale();
        //const currentTime = new Date().toLocaleTimeString(langLocale).replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        const dt = new Date();
        let t = dt.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false});
        t = t.replace(/\u200E/g, '');
        t = t.replace(/^([^\d]*\d{1,2}:\d{1,2}):\d{1,2}([^\d]*)$/, '$1$2');
        return t.toString();
    }

    static getLocale(){
        let language = window.navigator.languages && window.navigator.languages[0] ||
            window.navigator.language || window.navigator.userAgent.language;
        let languageDevice = "en";
        if (typeof (language) !== 'undefined' && language !== null && language !== ""){
            languageDevice = language.substring(0, 2);
        }
        return languageDevice;
    }

    static renderCapitalize(string){
        let stringCapitalize = string.toLowerCase();
        const resultCapitalize = stringCapitalize.slice(0,1).toUpperCase() + stringCapitalize.slice(1, stringCapitalize.length);
        return resultCapitalize;
    }

    static getMinutesBetweenHour(endTime, startTime){
        let start = moment.duration(endTime, "hh:mm");
        let end = moment.duration(startTime, "hh:mm");
        let diff = start.subtract(end);

        return [
            {"hour": diff.hours(), "minutes": diff.minutes()}
        ];
    }

    static openUrl(url){
        window.open(url, "_blank")
    }

    static getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    static backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "auto",
        });
    };

    static addEventData(jsonEventsByOrganizer){

        let arrTipology = [];
        var arrEventGrouped = [];
        var arrEvent = [];

        for (var i=0; i < jsonEventsByOrganizer.length; i++) {
            let event = null;
            event = jsonEventsByOrganizer[i];
            event.selectedEvent = false;
            event.tipology = "";
            event.publication = "";
            event.imgEvent = "";
            event.descriptionEvent = "";
            event.jsonSettings = null;
            let dict = {};

            /*if (arrEventGrouped.length > 0){
                    if (defaultValue !== event.name.it){
                        defaultValue = event.name.it;
                        dict[event.name.it] = event;
                        arrEventGrouped.push(dict);
                    }else{
                        dict[event.name.it] = event;
                        arrEventGrouped.push(dict);
                    }
                }else{
                    dict[event.name.it] = event;
                    arrEventGrouped.push(dict);
                    defaultValue = event.name.it;
                }*/

            dict[event.name.it] = event;
            arrEventGrouped.push(dict);
        }

        let eventWithSubscription = true;

        arrEventGrouped.forEach((value, i) => {
            if (Object.keys(value) !== 0) {
                var keyEvent = Object.keys(value)[0];
                var event = value[keyEvent];
                if (Object.keys(event.item_meta_properties).length > 0) {
                    for (var j=0; j < Object.keys(event.item_meta_properties).length; j++) {
                        var keyMeta = Object.keys(event.item_meta_properties)[j];
                        var valueMeta = Object.values(event.item_meta_properties)[j];

                        if (keyMeta === 'abbonamento'){
                            if(valueMeta === 'n'){
                                arrEvent.push(event);
                            }else{
                                if (eventWithSubscription){
                                    eventWithSubscription = false;
                                }
                            }
                        }

                    }
                }
            }
        });

        for (var z=0; z < arrEvent.length; z++) {
            let event = arrEvent[z];
            if (Object.keys(event.item_meta_properties).length > 0) {
                for (var j=0; j < Object.keys(event.item_meta_properties).length; j++) {
                    let keyMeta = Object.keys(event.item_meta_properties)[j];
                    let valueMetaTipology = Object.values(event.item_meta_properties)[j];
                    if (keyMeta === Utils.SlugTipology){
                        event.tipology = valueMetaTipology;
                        if (arrTipology.length > 0){
                            let result;
                            result = arrTipology.filter((valueTipology) => valueTipology.includes(valueMetaTipology));
                            if (result.length === 0){
                                arrTipology.push(valueMetaTipology);
                            }
                        }else{
                            arrTipology.push(valueMetaTipology);
                        }
                    }
                }
            }
        }

        if (arrEvent.length > 0){
            arrEvent.sort(function( a , b ){
                return a.date_from > b.date_from ? 1 : -1;
            });
        }

        return [
            {"arrEvent": arrEvent, "arrTipology" : arrTipology, "eventWithSubscription" : eventWithSubscription}
        ];

    }

}

export default Functions;

