import React, {Component} from 'react';
import Seo from "../../../elements/seo";
import 'moment/locale/it';
import 'moment-timezone';
import Event from './event';
import Spinner from "react-bootstrap/Spinner";
import '../../../../I18n/i18n';
import {t} from "i18next";

// Layout
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import Utils from "../../../Utils";
import DataManager from "../../../DataManager";
import Functions from "../../../Functions";
import UserClass from "../../../LocalStorage/UserClass";

class DetailEvent extends Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isLoadedReplicas: false,
            showEvent:true,
            msgError: null,
            nameEvent: "",
            resultData : [],
            arrReplicas: [],
            organizer: typeof(this.props.location.state.organizer) !== 'undefined' && this.props.location.state.organizer !== null ? this.props.location.state.organizer : null,
            event: typeof(this.props.location.state.event) !== 'undefined' && this.props.location.state.event !== null ? this.props.location.state.event : null,
            arrEvents: typeof(this.props.location.state.arrEvents) !== 'undefined' && this.props.location.state.arrEvents !== null ? this.props.location.state["arrEvents"] : null
        };

    }

    componentDidMount() {

        localStorage.removeItem('selectedSeats');
        if (typeof(this.props.location.state) !== 'undefined' && this.props.location.state != null) {
            setTimeout(()=>{
                if (typeof(this.props.location.state.event) !== 'undefined' && this.props.location.state.event != null) {
                    this.setState({
                        nameEvent: this.props.location.state.event.title.toString()
                    });
                }
                this.setState({
                    isLoaded: true
                });
                this.setState({
                    showEvent: false
                });
            },300)
        }else{
            const urlOrganizer = window.location.href;
            const checkUrlEvent = urlOrganizer.includes("idEvent");
            const checkUrlOrganizer = urlOrganizer.includes("idOrganizer");

            if (checkUrlOrganizer && checkUrlEvent){
                const query = new URLSearchParams(this.props.location.search);
                const slugOrganizer = query.get("idOrganizer");
                const idEvent = query.get("idEvent");

                setTimeout(()=>{
                    this.setState({
                        nameEvent: idEvent
                    });
                    this.getOrganizer(slugOrganizer);
                },300)
            }else{
                this.props.history.push({
                    pathname: '/error-404'
                });
            }
        }


    }

    getOrganizer(slugOrganizer){
        DataManager.getOrganizer(slugOrganizer).then((result) => {

            if (result !== null){
                const objOrganizer = result[0].organizer;
                if (objOrganizer !== null){
                    this.setState({
                        organizer: objOrganizer
                    });
                    this.getSettingsOrganizer(objOrganizer);
                }else{
                    const error = result[0].msgError;
                    if (typeof(error.message) !== 'undefined' && error.message !== null && error.message.includes('responseJson.organizer_info_text')) {
                        this.props.history.push({
                            pathname: '/error-404'
                        });
                    }else{
                        this.setState({
                            msgError : t("msgErrorGeneral"),
                            isLoaded: false
                        });
                    }
                }
            }else{
                this.setState({
                    msgError : t("msgErrorLoading"),
                    isLoaded: false
                });
            }
        });

    }

    getSettingsOrganizer(objOrganizer){
        DataManager.getSettingsOrganizer(objOrganizer).then((result) => {
            if (result !== null){
                const objOrganizer = result[0].organizer;
                if (objOrganizer !== null){
                    this.setState({
                        organizer: objOrganizer
                    });
                    this.getEvents(1, objOrganizer.slug);
                }else{
                    const error = result[0].msgError;
                    if (typeof(error.message) !== 'undefined' && error.message !== null && error.message.includes('responseJson.organizer_info_text')) {
                        this.props.history.push({
                            pathname: '/error-404'
                        });
                    }else{
                        this.setState({
                            msgError : error,
                            isLoaded: false
                        });
                    }
                }
            }else{
                this.setState({
                    msgError : t("msgErrorLoading"),
                    isLoaded: false
                });
            }
        });

    }

    getEvents(page, slug) {
        DataManager.getEvents(page, slug).then((result) => {
            if (result !== null){
                const arrEvents = result[0].events;
                if (arrEvents !== null  && typeof(arrEvents.results) !== 'undefined' && arrEvents.results.length > 0) {
                    let dict = [];
                    arrEvents.results.forEach((data)=>{
                        dict.push(data);
                        this.setState({
                            resultData: dict
                        });
                    });

                    if (typeof(arrEvents.next) !== 'undefined' && arrEvents.next != null) {
                        const page = arrEvents.next.split('=').pop();
                        this.getEvents(page,slug)
                    } else {
                        this.addEventData(this.state.resultData);
                    }
                }else{
                    console.log("no events");
                }
            }else{
                this.setState({
                    msgError : t("msgErrorLoading"),
                    isLoaded: false
                });
            }

        });

    }

    addEventData(jsonEventsByOrganizer){

        let arr = Functions.addEventData(jsonEventsByOrganizer);
        let arrEvent = arr[0].arrEvent;
        let dictEvent = [];
        let foundEvent = false;

        if (arrEvent.length > 0){
            for (var i=0; i < arrEvent.length; i++) {
                let objEvent = arrEvent[i];
                let slugEvent = objEvent.slug;
                const link = Utils.URL_API_SERVER_ORGANIZERS + this.state.organizer.slug + "/" + Utils.URL_API_SERVER_ORGANIZER_EVENTS + slugEvent + '/settings/';

                let headers = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
                        'Host': Utils.BASE_URL_SERVER_CUSTOM
                    }
                };

                fetch(link,headers)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != null) {
                            objEvent.imgEvent = responseJson.logo_image.toString();
                            objEvent.descriptionEvent = responseJson.frontpage_text.it.toString();
                            objEvent.jsonSettings = responseJson;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                if (i === arrEvent.length - 1){

                    for (var j=0; j < arrEvent.length; j++) {
                        let eventFinal = arrEvent[j];
                        if (eventFinal.name.it === this.state.nameEvent){
                            dictEvent.push(eventFinal);
                            foundEvent = true;
                        }
                    }
                    if (!foundEvent){
                        this.props.history.push({
                            pathname: '/error-404'
                        });
                    }else{

                        setTimeout(()=>{
                            if (dictEvent.length > 0){
                                this.setState({
                                    arrEvents: dictEvent
                                });
                                this.setState({
                                    event: dictEvent[0]
                                });
                                this.setState({
                                    isLoaded: true
                                });
                                this.setState({
                                    showEvents: false
                                });
                            }else{
                                this.setState({
                                    showEvents: true
                                });
                                this.setState({
                                    isLoaded: false
                                });
                            }
                        },300)
                    }

                }
            }
        }else{
            this.setState({
                showEvents: true
            });
            this.setState({
                isLoaded: false
            });
        }

    }

    render(){

        const { msgError, isLoaded, event, organizer, arrReplicas } = this.state;
        let dict = {};
        if (event !== null){
            dict["title"] = Utils.NAME_CUSTOMER + " | " + event.title.toString() + " - " + t("detail_event");
            dict["description"] = Utils.NAME_CUSTOMER + " | " + event.title.toString() + " - " + t("detail_event");
        }

        if (!isLoaded) {
            return <div className="centered-view"><Spinner animation="border" role="status" variant="primary" >
                <span className="visually-hidden">Caricamento...</span>
            </Spinner></div>
        }else{
            return (
                <>
                    {
                        dict
                        ? <Seo propsSeo={dict} /> : null
                    }
                    <Header />
                    <Event
                        arrReplicas={arrReplicas}
                        event={event}
                        organizer={organizer}
                    />
                    <Footer />
                </>
            );
        }

    }
}

export default DetailEvent;
