import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from "../lang/en.json";
import it from "../lang/it.json";

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: 'it',
    fallbackLng: 'it',
    resources: {
        en: en,
        it: it,
    },
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
