import React, { Component } from 'react';
import 'moment-timezone';
import { Card, Col, Container, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {Link} from 'react-router-dom';

// Import Images
import imgPlaceholder from "../../images/placeholderTicalo.png";

//config
import DataManager from "../DataManager";
import {t} from "i18next";

class OrganizerComponent extends Component{


	constructor(props) {
		super(props);
		this.state = {
			msgError: null,
			isLoaded: false,
			isLoadedCard: false,
			organizer: null,
			itemsOrganizer: []
		};
	}

    componentDidMount() {
        this.fetchOrganizers(1)

		if (typeof(this.props.values) !== 'undefined' && this.props.values !== null){
			if (typeof(this.props.values.wsRef) !== 'undefined' && this.props.values.wsRef !== null){
				if (typeof(this.props.values.wsRef.current) !== 'undefined' && this.props.values.wsRef.current !== null){
					this.props.values.wsRef.current.close();
				}
			}
		}

	}

	fetchOrganizers(page) {
		this.setState({
			msgError : ""
		});
		DataManager.getOrganizers(page).then((result) => {
			if(typeof (result) !== 'undefined' && result !== null){
				if (typeof (result['organizers']) !== 'undefined' && result['organizers'] !== null) {
					this.setState({
						isLoaded: true
					});
					let json = result['organizers'].results;
					if (json.length > 0){
						let arr = [];
						for (let i=0; i < json.length; i++) {
							let obj = json[i];
							if (typeof (obj.visible) !== 'undefined' && obj.visible !== null) {
								if(obj.visible){
									arr.push(obj);
								}
							}else{
								arr.push(obj);
							}
						}
						setTimeout(()=>{
							this.setState({
								itemsOrganizer: arr
							});
						},300)
					}
				} else {
					let error = t("msgErrorGeneral");
					if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
						error = result['msgError'];
					}
					this.setState({
						isLoaded: false,
						msgError : error
					});
				}
			}else{
				this.setState({
					isLoaded: false,
					msgError : t("msgErrorServer")
				});
			}
		});
	}


	renderButtonArrows(replaceKey, num) {
		if (num > 2) {
			return (
				<section style={{ display: "flex", justifyContent: "space-between" }}>
					<div className="prevArrow" id={"prevArrow" + replaceKey} onClick={(e) => this.moveLeft(e,replaceKey)}>
						<BsChevronLeft />
					</div>
					<div className="nextArrow" id={"nextArrow" + replaceKey} onClick={(e) => this.moveRight(e,replaceKey)}>
						<BsChevronRight />
					</div>
				</section>

			);
		}
	}

	moveRight = (e, id) => {
		e.preventDefault();
		const elScroll = document.getElementById("scrollOrganizers");
		elScroll.scrollLeft += 200;
	};

	moveLeft = (e,id) => {
		e.preventDefault();
		const elScroll = document.getElementById("scrollOrganizers");
		elScroll.scrollLeft -= 200;
	};

	render(){
		const { msgError, isLoaded, isLoadedCard, itemsOrganizer } = this.state;

		return (<div>
			{
				!isLoaded ?
					<div className="ml-10"><Spinner animation="border" role="status"
													variant="primary">
						<span className="visually-hidden">Caricamento...</span>
					</Spinner><span className="ml-5">{t("loading")}</span></div> :
					<div>
						{
							msgError !== "" ?
								<div>{t("error")}: {msgError}
								</div>
								:
								<section className="section-area section-sp1 blog-area">
									<Container>
										<Row className="flex-container" id={"scrollOrganizers"}>
											{itemsOrganizer.map((item, i) => (
												<Col xl={3} sm={4} xs={6} lg={4} className="scroll-horizontal mb-20"
													 key={i.toString()}>
													<Link to={{
														pathname: '/organizer/detail-organizer',
														state: item,
														search: `?idOrganizer=${item.vatNumber}`
													}}>
														<Card hidden={!isLoaded}>
															<Card.Img variant="top"
																	  src={typeof (item.headerImage) !== 'undefined' && item.headerImage !== null && item.headerImage.toString() !== "" ? process.env.REACT_APP_BASE_URL_MEDIA_PUB + item.headerImage.toString() : imgPlaceholder}
																	  className="img-event"/>
															<Card.Body className="card-data">
																<p className="p-card-thick p-card-nowrap">{typeof (item.commercialName) !== 'undefined' && item.commercialName !== null && item.commercialName !== "" ? item.commercialName : item.name}</p>
																{typeof (item.address) !== 'undefined' && item.address !== null && typeof (item.address.denormalizedForm) !== 'undefined' && item.address.denormalizedForm !== null && item.address.denormalizedForm.toString() !== "" ?
																	<p className="p-card-thick-sample">{item.address.denormalizedForm.toString()}</p> : ""}
															</Card.Body>
														</Card>
													</Link>
												</Col>
											))}
										</Row>
										{this.renderButtonArrows("scrollOrganizers", itemsOrganizer.length)}
									</Container>
								</section>

						}
					</div>
			}
		</div>);

	}
}

export default OrganizerComponent;