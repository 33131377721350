import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// All Pages Router
import Index from './pages/index';
import Faq from './pages/faq';
import Terms from './pages/terms';
import ContactUs from './pages/contact-us';
import Subscription from './pages/subscription';
import Error from './pages/error-404';
import DetailOrganizer from './pages/organizer/detail-organizer';
import DetailEvent from './pages/organizer/event/detail-event';
import Event from './pages/organizer/event/event';
import Individual from './pages/individual';
import Organizer from "./pages/organizer/organizer";

class Markup extends Component{

	render(){
		return(
			<>
				<BrowserRouter basename={'/'}>
				
					<Switch>

						<Redirect from="/" to="/index" exact component ={Index} />
						<Route path ="/index" exact component ={Index} />
						<Route path ="/subscription" exact component ={Subscription} />
						<Route path ="/individual" exact component ={Individual} />
						<Route path='/faq' exact component={Faq} />
						<Route path='/terms' exact component={Terms} />
						<Route path='/organizer/detail-organizer' exact component={DetailOrganizer} />
						<Route path='/organizer/event/detail-event' exact component={DetailEvent} />
						<Route path='/contact-us' exact component={ContactUs} />
						<Route component={Error} />
						
					</Switch>
					
					<PageScrollTop />
					
				</BrowserRouter>
				
				<BackToTop />
				
			</>
		);
	}
}

export default Markup;