import {useTranslation} from 'react-i18next';
import React, { Fragment, useEffect } from 'react';
import Markup from './markup/markup';
import './I18n/i18n';

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/themify/themify-icons.css";

// StyleSheet
import './App.scss';

//general
import Functions from "./markup/Functions";

const App = () => {

	const {t, i18n} = useTranslation();
	const [currentLanguage,setLanguage] = React.useState('');

	const changeLanguage = value => {
		i18n
			.changeLanguage(value)
			.then(() => setLanguage(value))
			.catch(err => console.log(err));
	};

	useEffect(() => {

		let langLocale = Functions.getLocale();
		if (typeof (langLocale) !== 'undefined' && langLocale !== null && langLocale !== "") {
			changeLanguage(langLocale);
		}else{
			changeLanguage("it");
		}

	}, [currentLanguage]);

	return (
		<div className="page-wraper">
			<Markup screenProps={{t, i18n}} />
		</div>
	)
};

export default App;

