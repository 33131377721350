import React from 'react';
import Helmet from 'react-helmet';
import Utils from "../Utils";
import '../../I18n/i18n';
import i18n from 'i18next';

const Seo = props => {

    const metaTags = [
        { itemprop: 'name', content: props.propsSeo.title },
        { itemprop: 'description', content: props.propsSeo.description },
        { name: 'description', content: props.propsSeo.description },

        { property: 'og:title', content: props.propsSeo.title },
        { property: 'og:description', content: props.propsSeo.description },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: Utils.NAME_CUSTOMER }
    ];

    return (
        <Helmet
            htmlAttributes={{
                lang: i18n.language.toString()
            }}
            title={props.propsSeo.title}
            meta={metaTags}
        >
        </Helmet>
    )
}

export default Seo
