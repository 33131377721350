import React from "react";
import {EnumPrenotation} from "../../../constants";

//CREATED, BOOKED, PAID, BLOCKED

function getColor(isBooked, isSelected, isStatusPrenotation) {
    if (isSelected) {
        return EnumPrenotation.SELECTED.toString();
    } else if (isStatusPrenotation) {
        return isStatusPrenotation;
    } else {
        if (isBooked) {
            return EnumPrenotation.LOCKED.toString();
        }
        return "default_seat";
    }
}

const Seat = props => {
    const isBooked = props?.status === false;
    let isStatusPrenotation = props?.statusPrenotation;

    let fontSizeSeat = 7;
    if (props.lengthSeat > 3){
        fontSizeSeat = 5;
    }

    let color = props.color;
    let statusSeat = false;
    let stringColor = getColor(isBooked, props.isSelected, isStatusPrenotation);
    if (isStatusPrenotation === ""){
        if (typeof (props?.statusLocked) !== 'undefined' && props?.statusLocked !== null){
            if (typeof (props?.statusLocked.status) !== 'undefined' && props?.statusLocked.status !== null){
                isStatusPrenotation = props?.statusLocked.status.toString()
                stringColor = getColor(isBooked, props.isSelected, isStatusPrenotation);
            }
        }
    }

    if (stringColor === EnumPrenotation.PAID.toString()){
        color = '#c3381b';
        statusSeat = true;
    }else if (stringColor === EnumPrenotation.LOCKED.toString()){
        color = '#666666';
        statusSeat = true;
    }else if (stringColor === EnumPrenotation.CREATED.toString()){
        color = '#e2e520';
        statusSeat = true;
    }else if (stringColor === EnumPrenotation.BOOKED.toString()){
        color = '#203ee5';
        statusSeat = true;
    }else if (stringColor === EnumPrenotation.BLOCKED.toString()){
        color = '#a19d9c';
        statusSeat = true;
    }else if (stringColor === EnumPrenotation.SELECTED.toString()){
        color = '#209f84';
    }else if (stringColor === EnumPrenotation.CANCELLED.toString()){
        color = '#f95858';
        statusSeat = true;
    }

    return (
        <div
            data-for="seat-tooltip"
            data-tip="seat-tooltip"
            data-iscapture="true"
            className={`${getColor(isBooked, props.isSelected, isStatusPrenotation)}`}
            id={props.id}
            onMouseEnter={e => {
                if (isBooked) {
                    e.target.style.cursor = "not-allowed";
                } else if (isStatusPrenotation.toString() === EnumPrenotation.PAID.toString()){
                    e.target.style.cursor = "not-allowed";
                } else if (isStatusPrenotation.toString() === EnumPrenotation.BLOCKED.toString()){
                    e.target.style.cursor = "not-allowed";
                }else if (isStatusPrenotation.toString() === EnumPrenotation.BOOKED.toString()){
                    e.target.style.cursor = "not-allowed";
                }else if (isStatusPrenotation.toString() === EnumPrenotation.CREATED.toString()){
                    e.target.style.cursor = "not-allowed";
                }else if (isStatusPrenotation.toString() === EnumPrenotation.LOCKED.toString()){
                    e.target.style.cursor = "not-allowed";
                }else{
                    e.target.style.cursor = "pointer";
                }
            }}
            onMouseLeave={e => {
                e.target.style.cursor = "";
            }}
            onClick={e => {
                if (isBooked) {
                    return;
                }
                if (isStatusPrenotation.toString() === EnumPrenotation.PAID.toString()){
                    return;
                }
                if (isStatusPrenotation.toString() === EnumPrenotation.BLOCKED.toString()){
                    return;
                }
                if (isStatusPrenotation.toString() === EnumPrenotation.BOOKED.toString()){
                    return;
                }
                if (isStatusPrenotation.toString() === EnumPrenotation.CREATED.toString()){
                    return;
                }
                if (isStatusPrenotation.toString() === EnumPrenotation.LOCKED.toString()){
                    return;
                }
                if (props.isSelected) {
                    props.onDeselect(props.seat);
                } else {
                    props.onSelect({...props.seat, row_number: props.rowNumber});
                }
            }}
            style={{
                width: 16,
                height: 16,
                marginLeft: 2,
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                fontSize: fontSizeSeat,
                borderRadius: 50,
                borderWidth: 1,
                borderColor: '#fff',
                display: 'flex',
                position: 'absolute',
                left: props.position?.x,
                top: props.position?.y,
                backgroundColor: color
            }}

        >
            {
                statusSeat ?
                    <div className="overlay-map" />
                    : null
            }
            {statusSeat ? "X" : props.seat.seatNumber} </div>
    );
};

export default Seat;

