import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {Row, Col, Container} from 'react-bootstrap';
import {t} from "i18next";

// Elements
import ButtonWizard from "./ButtonWizard";
import PaymentInfo from "./PaymentInfo";
import Functions from "../../Functions";
import Utils from "../../Utils";

//images
import WidgetEvent from "../../elements/widget-event";

export default class SelectPayment extends Component {

    componentDidMount() {

        /*window.addEventListener('popstate', (event) => {
            if (typeof(this.props.values.wsRef) !== 'undefined' && this.props.values.wsRef !== null){
                if (typeof(this.props.values.wsRef.current) !== 'undefined' && this.props.values.wsRef.current !== null){
                    this.props.values.wsRef.current.close();
                }
            }
        });*/

    }

    back = (e) => {
        e.preventDefault();
        setTimeout(()=>{
            Functions.backToTop();
        },100)
        this.props.prevStep();
    };

    renderTotTicket(){
        if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
            return <p className="fs-title-secondary-14 text-color-info mt-7 align-left-base">Posti selezionati: {this.props.values.arrCartPositions.length}</p>
        }else{
            return <p className="fs-title-secondary-14 text-color-info mt-7 align-left-base">Biglietti selezionati: {this.props.values.ticketsSelected.length}</p>
        }
    }

    render() {

        return(
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ "zIndex":1000000}}
                    >
                    </ToastContainer>
                </>
                <fieldset>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp7">
                            <Container className="container-map">
                                <Row>
                                    <Col xl={2}>
                                        <WidgetEvent {...this.props} />
                                    </Col>
                                    <Col xl={10}>
                                        <PaymentInfo {...this.props}/>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <ButtonWizard btnClicked={this.back} text="Indietro" />
                    </div>

                </fieldset>
            </>
        );

    }

}
