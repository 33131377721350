import {t} from "i18next";
import imgPlaceholder from "../../images/placeholderTicalo.png";
import Functions from "../Functions";
import React from "react";

const Event = (props ) => {

    React.useEffect(() => {

    }, []);

    return (
        <aside className="sticky-top pb-1">
            <div className="widget">
                <ul className="service-menu">
                    <h6>{t("event")}</h6>
                    <p className="p-card-thick">{props.event.title.toString()}</p>
                    <img
                        src={typeof (props.event.headerImage) !== 'undefined' && props.event.headerImage !== null && props.event.headerImage !== "" ? process.env.REACT_APP_BASE_URL_MEDIA_PUB + props.event.headerImage.toString() : imgPlaceholder}
                        className="img-event-sidebar" alt="immagine dell'evento"/>
                    <p className="date li-color-card"><i
                        className="far fa-calendar-alt" />&nbsp;
                        {Functions.convertDate(props.dataReply.startDate)}
                    </p>
                    <p className="title1-modal-event">{t("tipology")}:&nbsp;</p>
                    {typeof (props.event.genre) !== 'undefined' && props.event.genre !== null && typeof (props.event.genre.description) !== 'undefined' && props.event.genre.description !== null && props.event.genre.description.toString() !== "" ?
                        <p className="title2-modal-event truncate-event">{props.event.genre.description.toString()}</p> : null}
                </ul>
            </div>
        </aside>
    )
};

export default Event;
