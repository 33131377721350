import React, {Component} from "react";
import {Link} from "react-router-dom";
import Utils from "../../Utils";
import Messages from "../../Messages";
import {Card} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Badge from "react-bootstrap/Badge";
import firebase from '../../firebase';
import Functions from "../../Functions";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {t} from "i18next";
import DataManager from "../../DataManager";
import {calculateTotal, returnArrTicket} from "../../General";
import UserClass from "../../../markup/LocalStorage/UserClass";
import {getUserClientId, removeStorageMap} from "../../LocalStorage/storage";
import {EnumPrenotation} from "../../constants";

// Import Images
import cardCredit from "../../../images/icon/payment-credit-card.png";
import shieldSuccess from "../../../images/shield.png";


export default class PaymentInfo extends Component {

    constructor() {
        super();
        this.emailRef = React.createRef();
        this.nameRef = React.createRef();
        this.surnameRef = React.createRef();

        this.state = {
            totalAmount: 0,
            totalAmountApprox: "",
            phoneUser: "",
            emailUser: "",
            nameUser: "",
            surnameUser: "",
            showModalEmail: false,
            showModalEmailAndData: false,
            showErrorEmail: false,
            showErrorData: false,
            otpUser: "",
            method: "",
            visualizeTryAgain: true,
            validatedPhone: false,
            validatedOTP: true,
            verifiedPhone: false,
            verifiedOTP: false,
            visualizeOTP: true,
            visualizePayment: true,
            showModalPayment: false,
            visualizeBadgePayment: true,
            showLinkStripe: true,
            linkStripe: "",
            event: null,
            requireEmail:  false,
            requireNameAndSurname:  false
        };
    }

    componentDidMount() {

        if (typeof(this.props.values.arrUsers) !== 'undefined' && this.props.values.arrUsers !== null && this.props.values.arrUsers.length > 0){
            this.setState(
                {
                    emailUser: this.props.values.arrUsers[0].email
                }
            );
            let total = calculateTotal(this.props.values.arrSeats, false);
            this.setState({
                totalAmount: total
            });
        }

        this.configureCaptcha();

    }

    closeModalEmail = (e, method) => {
        e.preventDefault();
        this.setState(
            {
                showModalEmail: false
            }
        );
    };

    closeModalEmailAndData = () => {
        this.setState(
            {
                showModalEmailAndData: false
            }
        );
    };

    paymentTicket = (e, method) => {
        e.preventDefault();

        if(this.props.values.countDownFinish !== false){

            if (this.state.emailUser !== "") {
                this.setState(
                    {
                        showLinkStripe: true,
                        showModalPayment: true,
                        method: method
                    }
                );
                this.actionPostOrder();
            }else{
                this.setState(
                    {
                        showModalEmail: true
                    }
                );
            }

        }else{
            Functions.alertMsg(t("MsgTimerExpired"));
        }

    };

    actionPostOrder = () => {
        let hasMap = this.props.dataReply.hasMap;
        let instance = UserClass.getInstance();
        let token = instance.getTokenUser();
        let clientID = JSON.parse(getUserClientId());
        const arr = returnArrTicket(EnumPrenotation.CREATED.toString(), null, this.props.event, this.props.values.arrSeats, hasMap);

        DataManager.postOrder(token, arr, clientID, false, hasMap).then(async (result) => {
            this.setState(
                {
                    showModalPayment: false,
                }
            );
            if(typeof (result) !== 'undefined' && result !== null){
                if (typeof (result['orders']) !== 'undefined' && result['orders'] !== null) {
                    if (typeof (result['orders'].result) !== 'undefined' && result['orders'].result !== null) {
                        if (typeof (result['orders'].result.stripeLink) !== 'undefined' && result['orders'].result.stripeLink !== null && result['orders'].result.stripeLink !== "") {
                            let stripeLink = result['orders'].result.stripeLink;
                            removeStorageMap();
                            window.open(`${stripeLink}`, "_self");
                        }else{
                            Functions.alertMsg(t("MsgErrorLinkStripe"));
                        }
                    }else{
                        Functions.alertMsg(t("msgErrorGeneral"));
                    }
                } else {
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        Functions.alertMsg(result['msgError']);
                    }else{
                        Functions.alertMsg(t("msgErrorGeneral"));
                    }
                }
            }else{
                Functions.alertMsg(t("msgErrorGeneral"));
            }
        });
    };

    handlePaste = event => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    };

    handleChangePhone = (event) => {
        event.preventDefault();
        const {value} = event.target;
        if (!this.state.validatedPhone){
            this.setState({
                phoneUser : value,
            })
        }

    };

    handleChangeOtp = (event) => {
        event.preventDefault();
        const {value} = event.target;
        if (!this.state.validatedOTP){
            this.setState({
                otpUser : value,
            })
        }
    };

    configureCaptcha = () =>{
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("Recaptca validato")
            },
            defaultCountry: "IT"
        });
    };

    onSignInSubmit = () => {

        if(this.props.values.countDownFinish !== false){
            this.setState(
                {
                    verifiedPhone: true
                }
            );
            const phoneNumber = this.state.phoneUser;
            const appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    this.setState(
                        {
                            verifiedPhone: false
                        }
                    );
                    this.setState(
                        {
                            validatedPhone: true
                        }
                    );
                    this.setState(
                        {
                            validatedOTP: false
                        }
                    );
                    this.setState(
                        {
                            visualizeOTP: false
                        }
                    );
                }).catch((error) => {
                this.setState(
                    {
                        visualizeOTP: true,
                        verifiedOTP: false,
                        verifiedPhone: false,
                        validatedPhone: false
                    }
                );
                Functions.alertMsg(t("MsgUnverifiedPhoneNumber"));
            });
        }else{
            Functions.alertMsg(t("MsgTimerExpired"));
        }

    };

    onSubmitOTP = () =>{

        if(this.props.values.countDownFinish !== false){
            this.setState(
                {
                    verifiedOTP: true
                }
            );
            const code = this.state.otpUser;
            window.confirmationResult.confirm(code).then((result) => {
                this.setState(
                    {
                        validatedPhone: true,
                        visualizePayment: false
                    }
                );
                this.setState(
                    {
                        verifiedOTP: false
                    }
                );
                this.setState(
                    {
                        validatedOTP: true
                    }
                );
            }).catch((error) => {
                this.setState(
                    {
                        visualizeOTP: true,
                        verifiedOTP: false,
                        validatedPhone: false
                    }
                );
                Functions.alertMsg(t("MsgUnverifiedOTP"));
            });
        }else{
            Functions.alertMsg(t("MsgTimerExpired"));
        }
    };

    renderButtonVerifiedPhone() {
        if (this.state.verifiedPhone) {
            return (
                <Spinner animation="border" role="status" variant="primary" >
                    <span className="visually-hidden">Caricamento...</span>
                </Spinner>
            );
        }else{
            return(
                <div>
                    <Button onClick={this.onSignInSubmit} variant="secondary" size="sm" hidden={this.state.validatedPhone}>{t("verify")}</Button>
                </div>
            );
        }
    }

    renderButtonVerifiedOTP() {
        if (this.state.verifiedOTP) {
            return (
                <Spinner animation="border" role="status" variant="primary" >
                    <span className="visually-hidden">Caricamento...</span>
                </Spinner>
            );
        }else{
            return( <Button onClick={this.onSubmitOTP} variant="secondary" size="sm" hidden={this.state.validatedOTP}>{t("verify")}</Button>);
        }
    }

    confirmEmail = () => {

        const validEmail = this.refs.emailRef.value;

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(validEmail))
        {
            this.setState({
                showErrorEmail : false,
                showModalEmail: false,
                emailUser : this.refs.emailRef.value
            })
        }else{
            this.setState({
                showErrorEmail : true
            })
        }

    };

    confirmData = () => {

        const validEmail = this.refs.emailRef.value;

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(validEmail))
        {
            if(this.refs.nameRef.value !== "" && this.refs.surnameRef.value !== ""){
                if (this.props.values.arrUsers.length > 0){
                    if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
                        for (var i=0; i < this.props.values.arrCartPositions.length; i++) {
                            this.props.values.arrUsers[i].email = null;
                            this.props.values.arrUsers[i].name = null;
                            this.props.values.arrUsers[i].surname = null;
                            this.props.values.arrUsers[i].phone = null;
                            this.props.values.arrUsers[i].dateOfBirth = null;
                            this.props.values.arrUsers[i].placeOfBirth = null;
                            this.props.values.arrUsers[i].idBigliettoTabella = null;
                        }
                    }else{
                        for (var j=0; j < this.props.values.arrUsers.length; j++) {
                            this.props.values.arrUsers[j].email = null;
                            this.props.values.arrUsers[j].name = null;
                            this.props.values.arrUsers[j].surname = null;
                            this.props.values.arrUsers[j].phone = null;
                            this.props.values.arrUsers[j].dateOfBirth = null;
                            this.props.values.arrUsers[j].placeOfBirth = null;
                            this.props.values.arrUsers[j].idBigliettoTabella = null;
                        }
                    }
                }
                this.setState({
                    showErrorEmail : false,
                    showErrorData : false,
                    showModalEmailAndData: false,
                    visualizeBadgePayment: false,
                    emailUser : this.refs.emailRef.value,
                    nameUser : this.refs.nameRef.value,
                    surnameUser : this.refs.surnameRef.value
                })
            }else{
                this.setState({
                    showErrorData : true
                })
            }
        }else{
            this.setState({
                showErrorEmail: true,
                showErrorData: false
            })
        }

    };

    render() {
        const {phoneUser} = this.state;
        const {otpUser} = this.state;

        return (
            <>
                <>
                    <div>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            closeOnClick
                            newestOnTop={true}
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            style={{ "zIndex":1000000}}
                        >
                        </ToastContainer>
                    </div>
                </>
                <fieldset>
                    <Modal
                        size="lg"
                        show={this.state.showModalPayment}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>Stiamo elaborando il tuo ordine, sarai reindirizzato alla pagina di pagamento...</Modal.Body>
                        <Spinner className="center-spinner-loader" animation="border" role="status" variant="primary" >
                            <span className="visually-hidden">Caricamento...</span>
                        </Spinner>
                    </Modal>
                    <Modal size="lg" show={this.state.showModalEmail}
                           aria-labelledby="contained-modal-title-vcenter"
                           centered
                           style={{"zIndex": "10000000", "overflowY": "auto"}}
                    >
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {t("insert_email")}
                            </Modal.Title>
                            <Button onClick={(e) => {
                                this.setState({
                                    showModalEmail : false
                                })
                            }}><i className="fa-solid fa-xmark" /></Button>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <div>
                                <input type="email"
                                       name="email"
                                       id="email" ref="emailRef" />&nbsp;
                                <Button className="btn btn-primary confirm-button" onClick={this.confirmEmail}>
                                    {t("confirm")}
                                </Button>
                                <div hidden={!this.state.showErrorEmail}><p className="text-danger title-notice">{t("msgErrorValidMail")}</p></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal size="lg" show={this.state.showModalEmailAndData}
                           aria-labelledby="contained-modal-title-vcenter"
                           centered
                           style={{"zIndex": "10000000", "overflowY": "auto"}} onHide={this.closeModalEmailAndData}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Inserisci dati
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <div>
                                <input type="email"
                                       name="email"
                                       placeholder="Email"
                                       id="email" ref="emailRef" />
                            </div>&nbsp;
                            <div>
                            <input type="name"
                                   name="name"
                                   placeholder="Nome"
                                   id="name" ref="nameRef" />
                            </div>&nbsp;
                            <div>
                            <input type="surname"
                                   name="surname"
                                   placeholder="Cognome"
                                   id="surname" ref="surnameRef" />
                            </div>&nbsp;
                            <div>
                            <Button className="btn btn-primary confirm-button" onClick={this.confirmData}>
                                    Conferma
                                </Button>
                                <div hidden={!this.state.showErrorData}><p className="text-danger title-notice">{Messages.MsgCompileAllData}</p></div>
                                <div hidden={!this.state.showErrorEmail}><p className="text-danger title-notice">{Messages.MsgErrorValidMail}</p></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div className="form-card">
                        <h2 className="fs-title">Indicazioni di pagamento</h2>
                        <div validated={this.state.validatedPhone}>
                            <div id="sign-in-button"></div>
                            <Row className="align-items-center">
                                <Col xs="auto">
                                    <p>Inserisci un numero di cellulare per la verifica</p>
                                    <PhoneInput
                                        name="phone"
                                        required
                                        international
                                        id="phoneuser"
                                        onChange={phone => this.setState(
                                            {
                                                phoneUser: phone
                                            }
                                        )}
                                        defaultCountry="IT"
                                        placeholder="Numero di cellulare"
                                        value={phoneUser}
                                        onPaste={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}
                                        onCopy={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}/>
                                </Col>
                                <Col xs="auto" className="my-1">
                                    {this.renderButtonVerifiedPhone()}
                                </Col>
                            </Row>
                        </div>
                        <div hidden={this.state.visualizeOTP} validated={this.state.validatedOTP.toString()}>
                            <Row className="align-items-center">
                                <Col xs="auto">
                                    <p>Inserisci OTP</p>
                                    <input
                                        type="text"
                                        name="phoneotp"
                                        required
                                        id="phoneotp" onChange={this.handleChangeOtp}
                                        placeholder="Codice OTP"
                                        value={otpUser}
                                        onPaste={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}
                                        onCopy={(e)=>{
                                            e.preventDefault();
                                            return false;
                                        }}
                                    />
                                </Col>
                                <Col xs="auto" className="my-1">
                                    {this.renderButtonVerifiedOTP()}
                                </Col>
                            </Row>
                        </div>
                        <div className='align-two-card-flex' hidden={this.state.visualizePayment}>
                            <div hidden={this.state.visualizeBadgePayment} className='badge-success-payment'><Badge bg="success"
                            ><img src={shieldSuccess} alt="" width="25"/></Badge></div>
                            <Link to="" onClick={(e) => this.paymentTicket(e, "intero")}>
                                <Card
                                    bg='primary'
                                    key='primary'
                                    text='white'
                                    style={{ width: '18rem', height: '250px'}}
                                    className="mb-4"
                                >
                                    <Card.Body>
                                        <Card.Title>Pagamento diretto</Card.Title>
                                        <Card.Text>
                                            {this.state.totalAmount} €
                                        </Card.Text>
                                        <img src={cardCredit} alt="" className="rounded mx-auto d-block" width="150"/>
                                    </Card.Body>

                                </Card>
                            </Link>
                        </div>
                    </div>
                </fieldset>
            </>
        );
    }
}
