export const strings_general_App = {
    NAME_CUSTOMER: 'Botteghino Ticalo',
    NAME_COMPANY: "Bluenet srl",
    POWERED_BLUENET: 'Powered by Bluenet',
    EMAIL_SUPPORT: 'support@bluenetita.com',
    TIMER_DEFAULT_COUNTDOWN: 4,
    WS_URL: process.env.REACT_APP_BASE_URL_WS,
    TOKEN_BEARER_SIAE: process.env.REACT_APP_URL_BEARER_SIAE
};

export const EnumPrenotation = {
    SELECTED: 'SELECTED',
    BLOCKED: 'BLOCKED',
    BOOKED: 'BOOKED',
    PAID: 'PAID',
    CREATED: 'CREATED',
    LOCKED: 'LOCKED',
    CANCELLED: 'CANCELLED',
    UNLOCKED: 'UNLOCKED'
};

export const EnumStatusTitle = {
    BLACKLISTED: 'blacklisted',
    PENDING: 'pending',
    ISSUED: 'issued',
    CANCELLED: 'cancelled'
};

export const EnumAction = {
    SHOW: 'SHOW',
    DELETE: 'DELETE'
};

export const EnumStatusItem = {
    PENDING: 'pending',
    ISSUED: 'issued'
};

export const arr_status = [
    { name: "PAID", value: "paid"},
    { name: "BOOKED", value: "booked"},
    { name: "CANCELLED", value: "cancelled"}
];

export const arr_status_title = [
    { name: "VT"},
    { name: "VD"},
    { name: "ZT"},
    { name: "ZD"},
    { name: "AT"},
    { name: "AD"},
    { name: "FT"},
    { name: "FD"},
    { name: "BT"},
    { name: "BD"},
];

export const arr_support_code = [
    { name: "BT"},
    { name: "DD"}
];

export const arr_type_report = [
    { name: "rtc"},
    { name: "rte"}
];

export const arr_mode_system = [
    { name: "Botteghino", value: "BOTTEGHINO"},
    { name: "Sito web", value: "SITOWEB"},
    { name: "Totem", value: "TOTEM"}
];

export const arr_error_code = [
    { name: "CONNECTION_CLOSED_BY_SERVER"},
    { name: "CONNECTION_TIMED_OUT"},
    { name: "ERR_AUTH_INVALID_LOCKING_MECHANISM"},
    { name: "ERR_AUTH_MISSING_AUTHORIZATION_HEADER"},
    { name: "ERR_AUTH_ROLE_NOT_ALLOWED"},
    { name: "ERR_CLIENT_INVALID_EVENT_ID"},
    { name: "ERR_INTERNAL_SERVER_ERROR"},
    { name: "ERR_INVALID_RESPONSE"},
];

export const arr_reason_cancellation = [
    { name: "ERRATA_COMPILAZIONE", value: "001", label: "001"},
    { name: "STAMPA_NON_VALIDA", value: "002", label: "002"},
    { name: "TRANSAZIONE_NON_COMPLETA", value: "003", label: "003"},
    { name: "RICHIESTA_CLIENTE", value: "004", label: "004"},
    { name: "MOTIVI_SERVIZIO", value: "005", label: "005"},
    { name: "EVENTO", value: "006", label: "006"},
    { name: "CAMBIO_NOMINATIVO", value: "007", label: "007"},
    { name: "RIVENDITA", value: "008", label: "008"},
    { name: "FURTO_SMARRIMENTO", value: "009", label: "009"},
    { name: "UPSELLING", value: "010", label: "010"}
];

export const arr_code_genre_exclude = [
    { code: "70"},
    { code: "71"}
];

export const TYPE_TITLE = {
    'I1': 'Intero',
    'I2': 'Intero militari/ragazzi',
    'I3': 'Intero militari',
    'I4': 'Intero ragazzi',
    'I5': 'Intero anziani',
    'I6': 'Intero donna',
    'I7': 'Intero circoli',
    'R1': 'Ridotto militari/ragazzi',
    'R2': 'Ridotto militari',
    'R3': 'Ridotto ragazzi',
    'R4': 'Ridotto anziani',
    'R5': 'Ridotto donna',
    'R6': 'Ridotto circoli',
    'RX': 'Ridotto generico',
    'P1': 'Pomeridiano',
    'O1': 'Omaggio militari/ragazzi',
    'O2': 'Omaggio militari',
    'O3': 'Omaggio ragazzi',
    'O4': 'Omaggio anziani',
    'O5': 'Omaggio donna',
    'O6': 'Omaggio circoli',
    'OX': 'Omaggio generico',
    'PB': 'Prestazione obbligatoria',
    'PF': 'Prestazione facoltativa',
    'PO': 'Prestazione omaggio',
    'CB': 'Cessione obbligatoria',
    'CF': 'Cessione facoltativa',
    'CO': 'Cessione omaggio',
    'S1': 'Servizio',
    'SA': 'Servizio autorità',
    'SN': 'Inviti'
}

