import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Seo from "../elements/seo";
import '../../I18n/i18n';
import {t} from "i18next";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Utils from "../Utils";

//Error, Error 404 page
class Error extends Component{
	
	render(){
		let dict = {};
		dict["title"] = Utils.NAME_CUSTOMER + " | " + t("error_404");
		dict["description"] = Utils.NAME_CUSTOMER + " | " +  t("error_404");

		return (
			<>
				<Seo propsSeo={dict}></Seo>
				<Header />
				
				<div className="page-content bg-white">
					
					<section className="section-area section-sp2 error-404">
						<div className="container">
							<div className="inner-content">
								<h2 className="error-title">4<span></span>4</h2>
								<h3 className="error-text">{t("impossible_found_page")}</h3>
								<p>{t("page_not_found_or_name_changed")}</p>
								<div className="clearfix">
									<Link to="/" className="btn btn-primary">Home</Link>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default Error;