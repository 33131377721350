import React from "react";
import '../../../I18n/i18n';
import {t} from "i18next";

// Import Images
import imgPlaceholder from "../../../images/placeholderTicalo.png";

class Organizer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            organizer: this.props.organizer
        };
    }

    renderEmail() {
        if (typeof (this.state.organizer.mailOrganizer) !== 'undefined' && this.state.organizer.mailOrganizer !== null){
            return (
                <div className="ft-contact mt-15">
                    <div className="contact-bx">
                        <div className="icon"><i className="fas fa-envelope" /></div>
                        <div className="contact-info-element">
                            <span><a href={"mailto:" + this.state.organizer.mailOrganizer}>{t("contact_us")}</a></span>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    renderWebSite() {
        if (typeof (this.state.organizer.website) !== 'undefined' && this.state.organizer.website !== null && this.state.organizer.website !== ""){
            return (
                <div className="ft-contact mt-15">
                    <div className="contact-bx">
                        <div className="icon"><i className="fas fa-link" /></div>
                        <div className="contact-info-element">
                            <span><a target="_blank" href={this.state.organizer.website}>{t("website")}</a></span>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
        const { organizer } = this.state;

        return (
            <section className="section-sp1 detail-organizer-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-30 mt-20">
                            <div className="detail-organizer-thumb-area">
                                <img className="detail-organizer-thumb1 shadow-img-organizer img-event-detail" src={typeof (organizer.headerImage) !== 'undefined' && organizer.headerImage !== null && organizer.headerImage.toString() !== "" ? process.env.REACT_APP_BASE_URL_MEDIA_PUB + organizer.headerImage.toString() : imgPlaceholder} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <div className="heading-bx">
                                <h2 className="title">{typeof (organizer.commercialName) !== 'undefined' && organizer.commercialName !== null && organizer.commercialName !== "" ? organizer.commercialName : organizer.name}</h2>
                                {typeof (organizer.address) !== 'undefined' && organizer.address !== null && typeof (organizer.address.denormalizedForm) !== 'undefined' && organizer.address.denormalizedForm !== null && organizer.address.denormalizedForm.toString() !== "" ?
                                    <h6 className="text-secondary">{organizer.address.denormalizedForm.toString()}</h6> : ""}
                                {this.renderWebSite()}
                                {this.renderEmail()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Organizer;
