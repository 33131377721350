import Spinner from "react-bootstrap/Spinner";
import React from "react";

const GeneralModal = props => {

    return (<div className="modal-custom-loader d-block full-screen-popup modal-dialog-centered" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title text-center bold">{props.msgModal}</h6>
                </div>
                <div className="modal-body p-0">
                    <div className="imageBody text-center">
                        <Spinner className="center-spinner-loader" animation="border" role="status" variant="primary">
                            <span className="visually-hidden">Caricamento...</span>
                        </Spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default GeneralModal;