import React, {Component} from 'react';
import Seo from "../../elements/seo";
import Organizer from '../organizer/organizer';
import DataManager from '../../DataManager';
import Moment from 'react-moment';
import 'moment/locale/it';
import 'moment-timezone';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Placeholder from "react-bootstrap/Placeholder";
import {Card, Col, Row} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button';
import '../../../I18n/i18n';
import {t} from "i18next";

//Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// config
import Utils from "../../Utils";
import {saveTokenUser} from "../../LocalStorage/storage";

// Import Images
import imgPlaceholder from "../../../images/placeholderTicalo.png";

class DetailOrganizer extends Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isLoadedEvents: false,
            showEvents:true,
            msgError: null,
            resultData : [],
            itemsGrouped: [],
            items: [],
            itemsGroupedTipology: [],
            itemsEvent: [],
            itemsCarnet: [],
            organizer: this.props.location.state,
            eventWithSubscription: true
        };



    }

    componentDidMount() {

        this.setState({
            isLoadedEvents: false,
            msgError : ""
        });
        if (typeof(this.props.location.state) !== 'undefined' && this.props.location.state != null) {
            if (typeof(this.props.location.state.webappToken) !== 'undefined' && this.props.location.state.webappToken != null && this.props.location.state.webappToken.toString() !== "") {
                saveTokenUser(this.props.location.state.webappToken.toString());
                this.loadEvents(this.props.location.state.webappToken.toString());
            }else{
                this.props.history.push({
                    pathname: '/error-404'
                });
            }
        }else{
            const urlOrganizer = window.location.href;
            const checkUrlOrganizer = urlOrganizer.includes("idOrganizer");
            if (checkUrlOrganizer){
                const query = new URLSearchParams(this.props.location.search);
                const idOrganizer = query.get("idOrganizer");
                setTimeout(()=>{
                    this.getOrganizer(idOrganizer);
                },100)
            }else{
                this.props.history.push({
                    pathname: '/error-404'
                });
            }
        }

        console.log("detail organizer: ", this.props.location.state)

    }

    /*loginApp = () => {
        DataManager.login().then((result) => {
            if(typeof (result) !== 'undefined' && result !== null){
                if (typeof (result['login']) !== 'undefined' && result['login'] !== null) {
                    this.setState({
                        isLoaded: true
                    });
                    this.loadEvents(result['login'].token.toString());
                } else {
                    let error = t("msgErrorGeneral");
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        error = result['msgError'];
                    }
                    this.setState({
                        isLoadedEvents: false,
                        msgError : error
                    });
                }
            }else{
                this.setState({
                    isLoadedEvents: false,
                    msgError : t("msgErrorServer")
                });
            }
        });
    };*/

    loadEvents = (token) =>{
        DataManager.getEvents(token, 1).then((result) => {
            if(typeof (result) !== 'undefined' && result !== null){
                if (typeof (result['events']) !== 'undefined' && result['events'] !== null) {
                    this.setState({
                        isLoadedEvents: true,
                        isLoaded: true
                    });
                    let events = result['events'].results;
                    const eventsFiltered = events.filter(z => z.isSellable === true);
                    const eventsTypeEvent = eventsFiltered.filter(z => z.type === "event");
                    let arrEvent = this.renderGroupTipology(eventsTypeEvent);
                    if (arrEvent.length > 0){
                        this.setState({
                            itemsEvent : arrEvent
                        });
                    }
                    const eventsTypeCarnet = eventsFiltered.filter(z => z.type === "carnet");
                    let arrCarnet = this.renderGroupTipology(eventsTypeCarnet);
                    if (arrCarnet.length > 0){
                        this.setState({
                            itemsCarnet : arrCarnet
                        });
                    }
                } else {
                    let error = t("msgErrorGeneral");
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        error = result['msgError'];
                    }
                    this.setState({
                        isLoadedEvents: false,
                        msgError : error
                    });
                }
            }else{
                this.setState({
                    isLoadedEvents: false,
                    msgError : t("msgErrorServer")
                });
            }
        });
    };

    renderGroupTipology = (array) => {
        let dictEvent = [];

        if (array.length > 0) {
            for (let i=0; i < array.length; i++) {
                let eventFinal = array[i];
                let arr = null;
                let genre = eventFinal.genre.description.toString();
                if (dictEvent.length > 0){
                    let result = dictEvent.find((event) => event[genre]);
                    if (typeof(result) !== 'undefined' && result !== null){
                        let dict = result[genre];
                        const ids = dictEvent.slice();
                        dictEvent.splice(dictEvent.indexOf(result), 1);
                        dict.push(eventFinal);
                        let arrTemp = {};
                        arrTemp[genre] = dict;
                        arr = arrTemp;
                        if (arr !== null){
                            dictEvent.push(arr);
                        }
                    }else{
                        let dict = [];
                        dict.push(eventFinal);
                        let arrTemp = {};
                        arrTemp[genre] = dict;
                        arr = arrTemp;
                        if (arr !== null){
                            dictEvent.push(arr);
                        }
                    }
                }else{
                    let dict = [];
                    dict.push(eventFinal);
                    let arrTemp = {};
                    arrTemp[genre] = dict;
                    arr = arrTemp;
                    if (arr !== null){
                        dictEvent.push(arr);
                    }
                }
            }

        }

        return dictEvent;
    }

    renderGroupTipologyEvent(array, type) {
        if (array.length > 0){
            return array.map((item, i) =>(
                <div key={i}>
                    <h3 className="title text-color-secondary">{Object.keys(item)[0].toString()}</h3>
                    <Row className="flex-container" id={"scrollEvents" + type.toString() + Object.keys(item)[0].toString().replace(" ", "")} >
                        {item[Object.keys(item)[0].toString()].map((obj, i) =>(
                            <Col xl={3} sm={4} xs={6} lg={3} className="scroll-horizontal mb-20 ml-5" key={i.toString()}>
                                <Card onClick={(e) => this.onSelectEventInfo(e, obj)}>
                                    <Card.Img variant="top" src={obj.headerImage !== null && obj.headerImage !== ""  ? process.env.REACT_APP_BASE_URL_MEDIA_PUB + obj.headerImage.toString() : imgPlaceholder} className="img-event"  />
                                    <Card.Body className="card-data">
                                        <p className="p-card-thick p-card-nowrap">{obj.title.toString()}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {this.renderButtonArrows(type.toString() + Object.keys(item)[0].toString().replace(" ", ""), item[Object.keys(item)[0].toString()].length)}
                </div>
            ))
        }
        return null;

    }

    getOrganizer(idOrganizer){

        DataManager.getOrganizer(idOrganizer).then((result) => {
            if(typeof (result) !== 'undefined' && result !== null){
                if (typeof (result['organizer']) !== 'undefined' && result['organizer'] !== null) {
                    let organizer = result['organizer'];
                    this.setState({
                        organizer: organizer
                    });
                    if (typeof(organizer.webappToken) !== 'undefined' && organizer.webappToken != null && organizer.webappToken.toString() !== "") {
                        saveTokenUser(organizer.webappToken.toString());
                        this.loadEvents(organizer.webappToken.toString());
                    }else{
                        console.log("error loading organizer")
                    }
                } else {
                    let error = t("msgErrorGeneral");
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        error = result['msgError'];
                    }
                    this.setState({
                        isLoadedEvents: false,
                        msgError : error
                    });
                }
            }else{
                this.setState({
                    isLoadedEvents: false,
                    msgError : t("msgErrorServer")
                });
            }
        });
    }


    onSelectEventInfo = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.history.push({
            pathname: '/organizer/event/detail-event',
            state: {event : item, organizer: this.state.organizer},
            search: `?idOrganizer=${this.state.organizer.vatNumber}&idEvent=${item.title.toString()}`
        });
    };

    moveRight = (e, id) => {
        e.preventDefault();
        const elScroll = document.getElementById("scrollEvents" + id);
        elScroll.scrollLeft += 200;
    };

    moveLeft = (e,id) => {
        e.preventDefault();
        const elScroll = document.getElementById("scrollEvents" + id);
        elScroll.scrollLeft -= 200;
    };

    renderButtonArrows(replaceKey, numEvent) {
        if (numEvent > 3) {
            return (
                <section style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="prevArrow" id={"prevArrow" + replaceKey} onClick={(e) => this.moveLeft(e,replaceKey)}>
                        <BsChevronLeft />
                    </div>
                    <div className="nextArrow" id={"nextArrow" + replaceKey} onClick={(e) => this.moveRight(e,replaceKey)}>
                        <BsChevronRight />
                    </div>
                </section>

            );
        }
    }

    render(){

        const { msgError, isLoaded, isLoadedEvents, organizer, itemsGrouped, showEvents, itemsEvent, itemsCarnet } = this.state;

        let dict = {};
        if (typeof(organizer) !== 'undefined' && organizer !== null){
            dict["title"] = Utils.NAME_CUSTOMER + " | " + organizer.name + " - " + t("detail_organizer_events");
            dict["description"] = Utils.NAME_CUSTOMER + " | " + organizer.name + " - " + t("detail_organizer_events");
        }

        if (!isLoaded) {
            return <div className="centered-view"><Spinner animation="border" role="status" variant="primary" >
                <span className="visually-hidden">Caricamento...</span>
            </Spinner></div>
        }else{

        }
        return (
            <>
                <div>
                    <Header />
                    {
                        typeof(organizer) !== 'undefined' && organizer !== null
                            ?(
                                <div>
                                    {
                                        dict
                                            ? <Seo propsSeo={dict} /> : null
                                    }
                                    <Organizer
                                        organizer={organizer}
                                    />
                                    {
                                        isLoadedEvents ?
                                            <div>
                                                {
                                                    itemsEvent.length > 0
                                                        ? (
                                                            <div className="container">
                                                                <div className="heading-bx text-center">
                                                                    <h3 className="title">{t("events")}</h3>
                                                                </div>
                                                                {this.renderGroupTipologyEvent(itemsEvent, "event")}
                                                            </div>
                                                        )
                                                        : null
                                                }
                                                {
                                                    itemsCarnet.length > 0
                                                        ? (
                                                            <div className="container">
                                                                <div className="heading-bx text-center">
                                                                    <h3 className="title">{t("carnet")} </h3>
                                                                </div>
                                                                {this.renderGroupTipologyEvent(itemsCarnet, "carnet")}
                                                            </div>
                                                        )
                                                        : null
                                                }
                                            </div> :
                                            <div className="ml-10"><Spinner animation="border" role="status"
                                                                            variant="primary">
                                                <span className="visually-hidden">Caricamento...</span>
                                            </Spinner><span className="ml-5">{t("loading_shows")}</span></div>
                                    }
                                </div>
                            )
                            : <div>
                                {
                                    isLoaded ?
                                        <div className="centered-view"><Spinner animation="border" role="status"
                                                                                variant="primary">
                                            <span className="visually-hidden">Caricamento...</span>
                                        </Spinner></div> : <div>{t("error")}: {msgError}</div>
                                }
                            </div>
                    }
                </div>
                <Footer/>
            </>
        );
    }
}

export default DetailOrganizer;
