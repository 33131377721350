import React, { Component } from "react";
import Utils from "../../Utils";
import Functions from "../../Functions";
import Messages from "../../Messages";
import Badge from "react-bootstrap/Badge";
import 'moment/locale/it';
import 'moment-timezone';
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {t} from "i18next";

//images
import theaterEvent from "../../../images/icon/theater-event.png";
import imgPlaceholder from "../../../images/placeholderTicalo.png";
import {calculateTotal} from "../../General";
import {TYPE_TITLE} from "../../constants";

export default class ListPersonalInfo extends Component {

    constructor() {
        super();
        this.nameRef = React.createRef();
        this.surnameRef = React.createRef();
        this.cfRef = React.createRef();
        this.emailRef = React.createRef();
        this.phoneRef = React.createRef();
        this.dateOfBirthRef = React.createRef();
        this.placeOfBirthRef = React.createRef();

        this.state = {
            isEditing:false,
            isvalid: false,
            totalAmount: 0,
            message: "",
            idUserSelected: "",
            idUser: "",
            requireEmail: "",
            requireNameAndSurname: "",
            requirePhone: "",
            name: "",
            surname: "",
            email: "",
            phone: "",
            cf: "",
            placeOfBirth: "",
            dateOfBirth: "",
            users: [],
            showSlidebarEvents: false,
            isSubscription: false,
            showSlidebar: false
        };
    }

    componentDidMount() {

        if (typeof(this.props.values.arrSeats) !== 'undefined' && this.props.values.arrSeats !== null && this.props.values.arrSeats.length > 0){
            let list = [];
            for (let i = 0; i < this.props.values.arrSeats.length; i++) {
                let obj = this.props.values.arrSeats[i];
                let user = {};
                user["idUser"] = i;
                if (typeof(obj.typeTicket) !== 'undefined' && this.props.values.typeTicket !== null){
                    user["typeTicket"] = obj.typeTicket;
                }else{
                    user["typeTicket"] = null;
                }
                if (this.props.dataReply.hasMap){
                    user["category"] = obj.category.toString();
                    user["row_number"] = obj.row_number.toString();
                    user["seatGuid"] = obj.seatGuid.toString();
                    user["seatNumber"] = obj.seatNumber.toString();
                    user["uuid"] = obj.uuid.toString();
                }
                user["cf"] = "";
                user["email"] = "";
                user["name"] = "";
                user["surname"] = "";
                user["dateOfBirth"] = "";
                user["placeOfBirth"] = "";
                user["phone"] = "";
                user["idBigliettoTabella"] = "";
                list.push(user);
            }
            this.setState({
                users: list
            });
            if (typeof(this.props.values.arrUsers) !== 'undefined' && this.props.values.arrUsers !== null && this.props.values.arrUsers.length > 0){
                let arrUsers = [];
                for (let i = 0; i < this.props.values.arrSeats.length; i++) {
                    if (typeof(this.props.values.arrUsers[i]) !== 'undefined' && this.props.values.arrUsers[i] !== null){
                        let user = {};
                        user["idUser"] = i;
                        let obj = this.props.values.arrSeats[i];
                        let arr = this.props.values.arrUsers;
                        const el = arr.find(item => item.seatGuid === obj.seatGuid);
                        if (typeof (el) !== 'undefined' && el !== null) {
                            user["typeTicket"] = obj.typeTicket;
                            if (this.props.dataReply.hasMap){
                                user["category"] = el.category.toString();
                                user["row_number"] = el.row_number.toString();
                                user["seatGuid"] = el.seatGuid.toString();
                                user["seatNumber"] = el.seatNumber.toString();
                                user["uuid"] = el.uuid.toString();
                            }
                            user["cf"] = el.cf.toString();;
                            user["email"] = el.email.toString();;
                            user["name"] = el.name.toString();;
                            user["surname"] = el.surname.toString();;
                            user["dateOfBirth"] = el.dateOfBirth.toString();;
                            user["placeOfBirth"] = el.placeOfBirth.toString();;
                            user["phone"] = el.phone.toString();;
                            user["idBigliettoTabella"] = el.idBigliettoTabella.toString();;
                        }else{
                            if (typeof(obj.typeTicket) !== 'undefined' && this.props.values.typeTicket !== null){
                                user["typeTicket"] = obj.typeTicket;
                            }else{
                                user["typeTicket"] = null;
                            }
                            if (this.props.dataReply.hasMap){
                                user["category"] = obj.category.toString();
                                user["row_number"] = obj.row_number.toString();
                                user["seatGuid"] = obj.seatGuid.toString();
                                user["seatNumber"] = obj.seatNumber.toString();
                                user["uuid"] = obj.uuid.toString();
                            }
                            user["cf"] = "";
                            user["email"] = "";
                            user["name"] = "";
                            user["surname"] = "";
                            user["dateOfBirth"] = "";
                            user["placeOfBirth"] = "";
                            user["phone"] = "";
                            user["idBigliettoTabella"] = "";
                        }
                        arrUsers.push(user);
                    }else{
                        let obj = this.props.values.arrSeats[i];
                        let user = {};
                        user["idUser"] = i;
                        if (typeof(obj.typeTicket) !== 'undefined' && this.props.values.typeTicket !== null){
                            user["typeTicket"] = obj.typeTicket;
                        }else{
                            user["typeTicket"] = null;
                        }
                        if (this.props.dataReply.hasMap){
                            user["category"] = obj.category.toString();
                            user["row_number"] = obj.row_number.toString();
                            user["seatGuid"] = obj.seatGuid.toString();
                            user["seatNumber"] = obj.seatNumber.toString();
                            user["uuid"] = obj.uuid.toString();
                        }
                        user["cf"] = "";
                        user["email"] = "";
                        user["name"] = "";
                        user["surname"] = "";
                        user["dateOfBirth"] = "";
                        user["placeOfBirth"] = "";
                        user["phone"] = "";
                        user["idBigliettoTabella"] = "";
                        arrUsers.push(user);
                    }
                }
                if (arrUsers.length > 0){
                    this.setState({
                        users: arrUsers
                    });
                }
                console.log("componentDidMount SelectUser --------------")
                console.log(this.props.values.arrUsers)
                console.log(this.props.values.arrSeats)
                console.log(arrUsers)
                console.log(this.state.users)
                console.log("componentDidMount SelectUser --------------")
            }
            let total = calculateTotal(this.props.values.arrSeats, false);
            this.setState({
                totalAmount: total
            });
        }

    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name] : value,
        })
    };

    submitForm = (e, idx) => {
        e.preventDefault();
        e.stopPropagation();
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
            this.saveDataOfUser(this.state, idx);
        }else{
            Functions.alertMsg(Messages.MsgErrorValidMail);
        }
    };

    saveDataOfUser(obj, idx){
        const formatForNameAndSurname = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (formatForNameAndSurname.test(obj.name) || formatForNameAndSurname.test(obj.surname)){
            Functions.alertMsg(Messages.MsgErrorNameAndSurname);
        }else{
            this.saveUsers(obj, idx);
            this.setState(
                {
                    isvalid: false,
                    idUser: "",
                    message: "",
                    name: "",
                    surname: "",
                    email: "",
                    phone: "",
                    dateOfBirth: "",
                    placeOfBirth: "",
                    cf: ""
                }
            );
            this.onCancelClick();
        }
    }

    saveUsers = (user, idx) =>{

        let listUser = [];
        const index = this.state.users.findIndex(usr => usr.idUser === idx);
        listUser = [...this.state.users];
        const typeTicket = this.state.users[index].typeTicket;

        let objUser = {};
        objUser["idUser"] = idx;
        objUser["name"] = user.name;
        objUser["surname"] = user.surname;
        objUser["email"] = user.email;
        objUser["cf"] = user.cf;
        objUser["phone"] = user.phone;
        objUser["dateOfBirth"] = user.dateOfBirth;
        objUser["placeOfBirth"] = user.placeOfBirth;
        objUser["idBigliettoTabella"] = idx;

        if (this.props.dataReply.hasMap){
            objUser["category"] = user.category.toString();
            objUser["row_number"] = user.row_number.toString();
            objUser["seatGuid"] = user.seatGuid.toString();
            objUser["seatNumber"] = user.seatNumber.toString();
            objUser["uuid"] = user.uuid.toString();
        }
        objUser["typeTicket"] = typeTicket;

        listUser[index] = objUser;

        this.setState({ users: listUser });
        this.props.sendUsers(listUser);
    };

    onEditClick(idx) {
        const objUser = this.state.users.find(usr => usr.idUser === idx);
        this.setState({
            idUserSelected: idx,
            isEditing: true,
            name: objUser.name,
            surname: objUser.surname,
            email: objUser.email,
            phone: objUser.phone,
            dateOfBirth: objUser.dateOfBirth,
            placeOfBirth: objUser.placeOfBirth,
            cf: objUser.cf
        });
        if (this.props.dataReply.hasMap){
            this.setState({
                category: objUser.category,
                row_number: objUser.row_number,
                seatGuid: objUser.seatGuid,
                seatNumber: objUser.seatNumber,
                uuid: objUser.uuid
            });
        }
    }

    onDeleteClick(idx) {
        let arr = [];
        if (this.state.users.length > 0) {
            arr = this.state.users;
            const indexUser = arr.findIndex(
                obj => obj.idUser === idx
            );
            arr.splice(indexUser, 1);
            this.setState({ users: arr });
        }
    }

    onCancelClick() {
        this.setState({ idUserSelected: "" });
        this.setState({ isEditing: false });
    }

    onSaveClick(state, idx){
        for (var i=0; i < this.state.users.length; i++) {
            let user = this.state.users[i];
            if(idx === user.idUser){
                if (user.email && user.email.length === 0){
                    Functions.alertMsg(Messages.MsgErrorInsertMail);
                }else{
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email))
                    {
                        user.name = this.refs.nameRef.value;
                        user.surname = this.refs.surnameRef.value;
                        user.email = this.refs.emailRef.value;
                        user.phone = this.refs.phoneRef.value;
                        user.dateOfBirth = this.refs.dateOfBirthRef.value;
                        user.placeOfBirth = this.refs.placeOfBirthRef.value;
                        user.cf = this.refs.cfRef.value;
                    }else{
                        Functions.alertMsg(Messages.MsgErrorValidMail);
                    }
                }

            }
        }
        this.setState({isEditing:false});
    }

    renderListUsersSection(usersSelectedDuplicate){

        if (this.state.isEditing) {
            return(
                <tbody>
                {this.state.users.map((item, idx) =>(
                    this.state.idUserSelected === item.idUser
                        ? (
                            <tr key={idx.toString()}>
                                <td className="td-word-wrap"><p className="size-value-row size-inline-row">{this.renderTypeTitle(item)}</p></td>
                                <td><input type="text"
                                           name="name"
                                           id="name" defaultValue={item.name} ref="nameRef" onChange={this.handleChange} /></td>
                                <td><input type="text"
                                           name="surname"
                                           id="surname" defaultValue={item.surname} ref="surnameRef" onChange={this.handleChange} /></td>
                                <td><input type="date"
                                           name="dateOfBirth"
                                           id="dateOfBirth" defaultValue={item.dateOfBirth} ref="dateOfBirthRef" onChange={this.handleChange} /></td>
                                <td><input type="text"
                                           name="placeOfBirth"
                                           id="placeOfBirth" defaultValue={item.placeOfBirth} ref="placeOfBirthRef" onChange={this.handleChange} /></td>
                                <td>
                                    <input type="tel"
                                           name="phone"
                                           id="phone"
                                           defaultValue={item.phone} ref="phoneRef" onChange={this.handleChange}
                                           onKeyPress={(event) => {
                                               if (!/[0-9]/.test(event.key)) {
                                                   event.preventDefault();
                                               }
                                           }}
                                    /></td>
                                <td><input type="email"
                                           name="email"
                                           id="email" defaultValue={item.email} ref="emailRef" onChange={this.handleChange} /></td>
                                {this.renderActionSection(item.idUser)}
                            </tr>
                        )
                        : (
                            <tr key={idx.toString()}>
                                <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{this.renderTypeTitle(item)}</p></td>
                                <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.name}</p></td>
                                <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.surname}</p></td>
                                <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.dateOfBirth}</p></td>
                                <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.placeOfBirth}</p></td>
                                <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.phone}</p></td>
                                <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.email}</p></td>
                                {this.renderActionSection(item.idUser)}
                            </tr>
                        )
                ))}
                </tbody>
            );
        }

        return(
            <tbody>
            {this.state.users.map((item, idx) =>(
                <tr key={idx.toString()}>
                    <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{this.renderTypeTitle(item)}</p></td>
                    <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.name}</p></td>
                    <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.surname}</p></td>
                    <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.dateOfBirth}</p></td>
                    <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.placeOfBirth}</p></td>
                    <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.phone}</p></td>
                    <td className="td-word-wrap text-color-primary"><p className="size-value-row size-inline-row">{item.email}</p></td>
                    {this.renderActionSection(item.idUser)}
                </tr>
            ))}
            </tbody>
        );

    }

    renderTypeTitle(obj){
        if (typeof (obj) !== 'undefined' && obj !== null){
            let item = obj.typeTicket;
            let row_number = "";
            if (typeof (obj.row_number) !== 'undefined' && obj.row_number !== null){
                row_number = obj.row_number.toString();
            }
            let seatNumber = "";
            if (typeof (obj.seatNumber) !== 'undefined' && obj.seatNumber !== null){
                seatNumber = obj.seatNumber.toString();
            }
            let category = "";
            if (typeof (item.nameCategory) !== 'undefined' && item.nameCategory !== null){
                category = item.nameCategory.toString();
            }
            if (typeof (item.titleType) !== 'undefined' && item.titleType !== null){
                const arr = Object.keys(TYPE_TITLE).filter(z => z.toString() === item.titleType.toString());
                let strTypeTitle = item.titleType.toString();
                if (arr.length > 0){
                    let str = arr[0].toString();
                    strTypeTitle = TYPE_TITLE[str];
                }
                return <div>{category !== "" ? category : null} {row_number !== "" ? t("row")  + " " + row_number : null} {seatNumber !== "" ? t("seat")  + " " + seatNumber : null} {typeof (item.price) !== 'undefined' && item.price !== null ? "(" + strTypeTitle + " - " + item.price.totalPrice.toString() + " €)" : ""}</div>;
            }
            return <div>{category !== "" ? category : null} {row_number !== "" ? t("row")  + " " + row_number : null} {seatNumber !== "" ? t("seat")  + " " + seatNumber : null} {typeof (item.price) !== 'undefined' && item.price !== null ? "(" + item.price.totalPrice.toString() + " €)" : ""}</div>;

        }
        return null;

    }

    renderActionSection(idx){
        if (this.state.isEditing){
            if (this.state.idUserSelected !== idx){
                return(
                    <td key={idx.toString()}>
                        <span className="icons-table">
                            <i className="icons-table-users fa fa-edit" onClick={this.onEditClick.bind(this, idx)} />
                        </span>
                    </td>
                );
            }
            return(
                <td key={idx.toString()}>
                      <span className="icons-table">
                        <i className="icons-table-users fa fa-times-circle" onClick={this.onCancelClick.bind(this)} />
                        <i className="icons-table-users fa fa-save" onClick={(e) => this.submitForm(e, idx)} />
                      </span>
                </td>
            );
        }
        return(
            <td key={idx.toString()}>
                <span className="icons-table">
                    <i className="icons-table-users fa fa-edit" onClick={this.onEditClick.bind(this, idx)} />
                </span>
            </td>
        );
    }

    renderTotTicket(){
        /*const { values } = this.props;
        if (values.typeTicket === Utils.TicketModularSubscription || values.typeTicket === Utils.TicketFixedSubscription){
            return <div>
                {
                    typeof(values.itemsEventSelected) !== 'undefined' && values.itemsEventSelected !== null
                        ?(
                            <Offcanvas show={this.state.showSlidebarEvents} onHide={() => this.setState({ showSlidebarEvents: false })} {...values} placement='end' name='end' className="overlay-event-menu">
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Spettacoli selezionati</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    {values.itemsEventSelected.map((item, index) => (
                                        <Row className="justify-content-md-center row" key={index.toString()}>
                                            <Col className="col" screen_size={6}>
                                                <p className="p-card-thick">{item.name.it}</p>
                                                <img src={item.imgEvent !== ""  ? item.imgEvent : imgPlaceholder}
                                                     className="img-event-sidebar" alt="immagine dell'evento" />
                                                <p className="date li-color-card"><i className="far fa-calendar-alt" />&nbsp;
                                                    {Functions.convertDate(item.date_from)}
                                                </p>
                                                <p className="title1-modal-event">Tipologia:&nbsp;</p>
                                                <p className="title2-modal-event">{item.tipology}</p>
                                                <div className="divider" />
                                            </Col>
                                        </Row>
                                    ))}
                                </Offcanvas.Body>
                            </Offcanvas>
                        )
                        : null
                }
                <div className='align-two-text-flex'>
                <Badge bg="info" className="fs-title-secondary-14 mt-7 mb-10">
                    <i className="fa fa-chair" />&nbsp;
                    Posti selezionati: {this.props.values.arrCartPositions.length}
                </Badge>
                <div>
                    <img src={theaterEvent} alt="Spettacoli selezionati" height="20px" onClick={() => this.setState({ showSlidebarEvents: true })}
                         className="fs-subtitle-right img-num-event-selected" />
                    <Badge bg="secondary" className="badge-event-selected">{this.props.values.itemsEventSelected.length}</Badge>
                </div>
            </div></div>
        }else{
            if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
                return <Badge bg="info" className="fs-title-secondary-14 mb-10 cursor-pointer-text" onClick={this.handleShowSlidebar}><i className="fa fa-chair"></i>&nbsp;Posti selezionati: {values.arrCartPositions.length}</Badge>
            }else{
                return <Badge bg="info" className="fs-title-secondary-14 mb-10 cursor-pointer-text" onClick={this.handleShowSlidebar}><i className="fa fa-ticket-alt"></i>&nbsp;Biglietti selezionati: {values.ticketsSelected.length}</Badge>
            }
        }*/
        return <Badge bg="info" className="fs-title-secondary-14 mb-10"><i className="fa fa-ticket-alt" />&nbsp;{t("ticket_selected")}: {this.props.values.arrSeats.length}</Badge>

    }

    renderColumnTable(){
        const typeTicket = "Tipologia";
        const name = "Nome";
        const surname = "Cognome";
        const dateOfBirth = "Data di nascita";
        const placeOfBirth = "Luogo di nascita";
        const phone = "Telefono";
        const email = "Email";
        const actions = "Azioni";

        let arrColumn = [];
        arrColumn.push(typeTicket);
        arrColumn.push(name);
        arrColumn.push(surname);
        arrColumn.push(dateOfBirth);
        arrColumn.push(placeOfBirth);
        arrColumn.push(phone);
        arrColumn.push(email);
        arrColumn.push(actions);

        return <thead>
        <tr>
            {arrColumn.map((item, idx) =>(
                <th key={idx.toString()}>{item}</th>
            ))}
        </tr>
        </thead>
    }

    renderPriceSeat(seat){

        if (typeof (seat.typeTicket) !== 'undefined' && seat.typeTicket !== null) {
            if (typeof (seat.typeTicket.bundles) !== 'undefined' && seat.typeTicket.bundles !== null && seat.typeTicket.bundles.length > 0) {
                let bundleType = "";
                if (typeof (seat.typeTicket.bundles[0].bundle_type) !== 'undefined' &&  seat.typeTicket.bundles[0].bundle_type !== null) {
                    bundleType = seat.typeTicket.bundles[0].bundle_type + ":";
                    return <option disabled>{Functions.nameProduct(seat.typeTicket.name.it)}: {seat.typeTicket.default_price}€ / {bundleType} {seat.typeTicket.bundles[0].designated_price}€</option>
                }else{
                    return <option disabled>{Functions.nameProduct(seat.typeTicket.name.it)}: {seat.typeTicket.default_price}€</option>
                }
            }else{
                return <option disabled>{Functions.nameProduct(seat.typeTicket.name.it)}: {seat.typeTicket.default_price}€</option>
            }
        }else{
            if (typeof (seat.bundles) !== 'undefined' && seat.bundles !== null && seat.bundles.length > 0) {
                let bundleType = "";
                if (typeof (seat.bundles[0].bundle_type) !== 'undefined' &&  seat.bundles[0].bundle_type !== null) {
                    bundleType = seat.bundles[0].bundle_type + ":";
                    return <option disabled>{Functions.nameProduct(seat.name.it)}: {seat.default_price}€ / {bundleType} {seat.bundles[0].designated_price}€</option>
                }else{
                    return <option disabled>{Functions.nameProduct(seat.name.it)}: {seat.default_price}€</option>
                }
            }else{
                return <option disabled>{Functions.nameProduct(seat.name.it)}: {seat.default_price}€</option>
            }
        }
    };


    renderSeatsInSlidebar(){
        if (typeof(this.props.values.typeEvent) !== 'undefined' && this.props.values.typeEvent !== null){
            if (this.props.values.typeEvent === Utils.TypeEventSingleWithMap) {
                if (typeof(this.props.values.arrCartPositions) !== 'undefined' && this.props.values.arrCartPositions !== null){
                    if (this.props.values.arrCartPositions.length > 0){
                        return <div>
                            <div className="divider" />
                            {this.props.values.arrCartPositions.map((seat, i) => (
                                this.renderSeatWithMap(seat, i)
                            ))}
                        </div>
                    }
                }
            }else{
                if (typeof(this.props.values.ticketsSelected) !== 'undefined' && this.props.values.ticketsSelected !== null){
                    if (this.props.values.ticketsSelected.length > 0){
                        return <div>
                            <div className="divider" />
                            {this.props.values.ticketsSelected.map((seat, i) => (
                                this.renderSeatWithoutMap(seat, i)
                            ))}
                        </div>
                    }
                }
            }
        }
    };

    renderSeatWithoutMap(seat, i){
        return <Row className="justify-content-md-center row" key={i.toString()}>
            <Col className="col" screen_size={6}>
                <div className='align-two-text-flex'>
                    <p>{seat.category_name}</p>
                </div>
                {this.renderPriceSeat(seat)}
                <div className="divider" />
            </Col>
        </Row>
    };

    renderSeatWithMap(seat, i){
        return <Row className="justify-content-md-center row" key={i.toString()}>
            <Col className="col" screen_size={6}>
                <div className='align-two-text-flex'>
                    <p>{seat.category}</p>
                </div>
                <div className='align-two-text-flex'>
                    <p className="title1-modal-event">Fila&nbsp;<p className="title2-modal-event">{seat.row_number}</p></p>
                    <p className="title1-modal-event">Posto&nbsp;<p className="title2-modal-event">{seat.seat_number}</p></p>
                </div>
                {this.renderPriceSeat(seat)}
                <div className="divider" />
            </Col>
        </Row>
    };

    handleCloseSlidebar = () => {
        this.setState({'showSlidebar': false});
    };

    handleShowSlidebar = () => {
        this.setState({'showSlidebar': true});
    };

    render() {
        const { values } = this.props;
        let usersSelectedDuplicate = [];
        if (typeof(this.props.values.arrUsersDuplicate) !== 'undefined' && this.props.values.arrUsersDuplicate !== null){
            if (this.props.values.arrUsersDuplicate.length > 0){
                usersSelectedDuplicate = this.props.values.arrUsersDuplicate;
            }
        }
        return (
            <div>
                <Offcanvas show={this.state.showSlidebar}
                           onHide={this.handleCloseSlidebar} {...this.props} placement='end' name='end' className="overlay-event-menu">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Posti selezionati</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {this.renderSeatsInSlidebar()}
                    </Offcanvas.Body>
                </Offcanvas>
                <div className="form-card">
                    <div className='align-two-text-flex'>
                        <h2 className="fs-title">{t("personal_data")}</h2>
                        <h3 className="fs-title-secondary-18 text-color-secondary mt-7">{t("total")}: {this.state.totalAmount} €</h3>
                    </div>
                    {this.renderTotTicket()}
                    <div className="table-responsive">
                        <table className="align-middle space-between-cell">
                            {this.renderColumnTable()}
                            {this.renderListUsersSection(null)}
                        </table>
                    </div>
                    <p className="title-notice">* {t("names_all_ticket")}</p>
                </div>
            </div>
        );
    }
}
