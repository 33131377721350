import React, { Component,} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import {t} from "i18next";
import {arr_code_genre_exclude, TYPE_TITLE} from "../../constants";
import {
    renderTotalPriceByTypeTicket,
    renderTotalByTypeTicket,
    calculateTotalWithoutMap
} from "../../General";

// Elements
import Functions from "../../Functions";

export default class ListSector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            msgError: null,
            msgErrorCart: "",
            isLoaded: true,
            isLoadedCart: false,
            itemsSelected: [],
            totItems: 0,
            sectorSelected: "",
            resultDataItems: [],
            resultDataQuotas: [],
            resultDataCategories: [],
            statusTicketSelected: true,
            showModalInfoEvent: false,
            arrEvents: [],
            arrSector: [],
            arrSeats: [],
            totalAmount: 0.0,
            maxNumTicket: 0,
            items: this.props.items,
            reply: this.props.dataReply,
            event: this.props.event,
            organizer: this.props.organizer
        };

    }

    componentDidMount() {
        console.log("componentDidMount ListSector")
        console.log(this.props)
        console.log("componentDidMount ListSector")

        let maxTicket = 0;
        if(typeof (this.props.event) !== 'undefined' && this.props.event !== null){
            if(typeof (this.props.event.maximumBuyableSeatsPerOrder) !== 'undefined' && this.props.event.maximumBuyableSeatsPerOrder !== null){
                maxTicket = this.props.event.maximumBuyableSeatsPerOrder.default;
                if(typeof (this.props.event.maximumBuyableSeatsPerOrder.bySalesChannel) !== 'undefined' && this.props.event.maximumBuyableSeatsPerOrder.bySalesChannel !== null){
                    const arrChannel = this.props.event.maximumBuyableSeatsPerOrder.bySalesChannel;
                    const arr = Object.keys(arrChannel).filter(z => z.toString() === "WEB");
                    if (arr.length > 0){
                        let channel = arr[0].toString();
                        maxTicket = arrChannel[channel];
                    }
                }
            }
        }
        this.setState({
            maxNumTicket: maxTicket
        });

        if (typeof (this.props.values.arrSeats) !== 'undefined' && this.props.values.arrSeats !== null && this.props.values.arrSeats.length > 0){
            this.setState({
                arrSeats: this.props.values.arrSeats
            });
            this.props.seatsSelection(this.props.values.arrSeats);
            const total = calculateTotalWithoutMap(this.props.values.arrSeats, false);
            this.setState({
                totalAmount: total
            });
        }

    }

    addCartItem(item, obj) {
        let arrTemp = [...this.state.arrSeats];

        if(arrTemp.length === 0){
            if (obj.availability > 0){
                obj["typeTicket"] = item;
                obj["category"] = obj.name.it.toString();

                let objSelected = Object.assign({}, obj)
                const newSeats = arrTemp.concat([objSelected]);
                this.setState({
                    arrSeats: newSeats
                });
                const total = calculateTotalWithoutMap(newSeats, false);
                this.setState({
                    totalAmount: total
                });
                this.props.seatsSelection(newSeats);
            }
        }else{
            const arrCategory = arrTemp.filter(z => z.code.toString() === obj.code.toString());
            if (arrTemp.length < this.state.maxNumTicket){
                if (arrCategory.length < obj.availability){
                    obj["typeTicket"] = item;
                    let objSelected = Object.assign({}, obj)
                    const newSeats = arrTemp.concat([objSelected]);
                    this.setState({
                        arrSeats: newSeats
                    });
                    const total = calculateTotalWithoutMap(newSeats, false);
                    this.setState({
                        totalAmount: total
                    });
                    this.props.seatsSelection(newSeats);
                }
            }else{
                Functions.alertMsg(t("max_ticket_selected"))
            }
        }

    }

    removeCartItem(item, obj) {
        if(this.state.arrSeats.length > 0){
            let arrTemp = [...this.state.arrSeats];
            let newSeats = null;
            newSeats = arrTemp.slice();
            const selectedItem = newSeats.find(z => z.code.toString() === obj.code.toString() && z.typeTicket.titleType.toString() === item.titleType.toString());
            if(typeof (selectedItem) !== 'undefined' && selectedItem !== null){
                newSeats.splice(newSeats.indexOf(selectedItem), 1);
                this.setState({
                    arrSeats: newSeats
                });
                const total = calculateTotalWithoutMap(newSeats, false);
                this.setState({
                    totalAmount: total
                });
                this.props.seatsSelection(newSeats);
            }
        }
    }

    sendTickets(totFloatPriceItem, arrTickets){
        this.props.sendTickets(arrTickets, totFloatPriceItem);
    }

    /*totalItems(objItem) {
       let totItems = 0;
       for (var i = 0; i < this.state.itemsSelected.length; i++) {
           let item = this.state.itemsSelected[i];
           if (item.id === objItem.id) {
               totItems = totItems + 1;
           }
       }
       return totItems;
   }

   renderButtonsAddAndRemoveWithTotal(objItem){
       return (
           <div className="buttons-cart">
               <Button
                   variant="outline-primary"
                   size="small"
                   onClick={(e) => this.removeCartItem(e, objItem)}
               >
                   <i className="fas fa-minus"/>
               </Button>
               <p className="margin-top-event">{this.totalItems(objItem)}</p>
               <Button
                   variant="outline-primary"
                   size="small"
                   onClick={(e) => this.addCartItem(e, objItem)}
               >
                   <i className="fas fa-plus"/>
               </Button>
           </div>
       );
   }

   renderQuotasForEvent(objItem) {
       let idItem = objItem.id;

       for (var i = 0; i < this.state.resultDataQuotas.length; i++) {
           let obj = this.state.resultDataQuotas[i];
           const selectedItem = obj.items.find(element => element === idItem);
           if (typeof (selectedItem) !== 'undefined' && selectedItem !== null) {
               if (selectedItem === idItem) {
                   if (obj.available_number === null){
                       return (
                           <p className="text-success margin-bottom-text-danger">Biglietti illimitati</p>
                       );
                   }else{
                       if (obj.available_number > 0 && obj.available_number <= obj.size){
                           return (
                               <p className="text-success margin-bottom-text-danger">Sono disponibili {obj.available_number} biglietti</p>
                           );
                       }else{
                           return (
                               <p className="text-danger margin-bottom-text-danger">Biglietti esauriti</p>
                           );
                       }
                   }
               }
           }
       }
   }

  renderSingleItem(item) {

       const arrObj = [];

       if (item === this.state.sectorSelected){
           let arr = this.state.arrSector[item].itemHashMap;

           if (arr != null){
               arr.map((objItem) => {
                   if (typeof (objItem.sales_channels) !== 'undefined' && objItem.sales_channels !== null && objItem.sales_channels.length > 0) {
                       const checkWeb = objItem.sales_channels.every(item => item === Utils.KEY_WEB);
                       if (checkWeb){
                           let presale = 0;
                           let bundleType = "";
                           if (typeof (objItem.bundles) !== 'undefined' && objItem.bundles !== null && objItem.bundles.length > 0) {
                               let designatedPrice = objItem.bundles[0].designated_price * 100;
                               presale = parseFloat(designatedPrice/100).toFixed(2);
                               if (typeof (objItem.bundles[0].bundle_type) !== 'undefined' &&  objItem.bundles[0].bundle_type !== null) {
                                   bundleType = objItem.bundles[0].bundle_type + ":";
                               }
                           }

                           let name = objItem.name.it;
                           let nameProduct = Functions.nameProduct(name);

                           arrObj.push(<div>
                               <div className="information-cart">
                                   <p>{nameProduct}</p>
                                   <p>Prezzo: <strong>{objItem.default_price}€</strong></p>
                                   <p>{presale > 0 ? bundleType + " " : "     "} <strong>{presale > 0 ? presale + "€" : ""}</strong></p>
                               </div>
                               {this.renderButtonsAddAndRemoveWithTotal(objItem)}
                               {this.renderQuotasForEvent(objItem)}
                           </div>);
                       }
                   }
               })
           }
       }

       return arrObj;

   }

   renderItems() {
       return (
           <div>
               <h5>Totale: {this.state.totItems}€</h5>
               {Object.keys(this.state.arrSector).map((objItem) => (
                   <div>
                       {this.renderSingleItem(objItem)}
                   </div>
               ))}
               <div hidden={!this.state.isLoadedCart}><p className="text-color-secondary title-notice">{this.state.msgErrorCart}</p></div>
           </div>
       );
   }

   renderTotBadgeByCategory(sector) {
       if(this.state.itemsSelected.length > 0){
           var idCategory = 0;
           var countItemsSelected = 0;

           for (var i = 0; i < this.state.resultDataCategories.length; i++) {
               let objCategory = this.state.resultDataCategories[i];
               if (objCategory.name.it === sector){
                   idCategory = objCategory.id;
               }
           }

           for (var z = 0; z < this.state.itemsSelected.length; z++) {
               let objItem = this.state.itemsSelected[z];
               if (objItem.category === idCategory){
                   countItemsSelected = countItemsSelected + 1;
               }
           }

           return (
               <Badge bg="info" pill>
                   {countItemsSelected}
               </Badge>
           );
       }
   }

   renderEventInModal() {
       let tot = 0;
       //tot = this.state.event.jsonSettings.max_items_per_order - this.state.itemsSelected.length;

       return (
           <Modal
               show={this.state.showModalInfoEvent}
               onHide={this.handleCloseInfoEvent}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               style={{"zIndex": "10000000", "overflow-y": "auto", "height": "90vh"}}
           >
               <Modal.Header closeButton>
                   <Modal.Title id="contained-modal-title-vcenter">
                       Hai selezionato il settore {this.state.sectorSelected}
                   </Modal.Title>
               </Modal.Header>
               <Modal.Body className="show-grid">
                   <div style={{"overflow-y": "auto", "height": "60vh"}}>
                       <section className="section-area section-sp6 appointment-wraper">
                           <div className="container">
                               <div className="row justify-content-center">
                                   <div className="col-xl-9 col-lg-6 col-md-6">
                                       <div className="individual-form form-wraper">
                                           <h4 className="text-color-secondary">Seleziona biglietto</h4>
                                           <p className="text-danger margin-bottom-text-danger">Puoi
                                               selezionare {tot} biglietto/i</p>
                                           {this.renderItems()}
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </section>
                   </div>
               </Modal.Body>
           </Modal>
       );

   }*/

    renderTypeTicketSection = (item, obj) => {

        if (item.seatingOrder.toString() === obj.code.toString()){
            return <div className="header-list-group-typeticket">
                {this.renderTypeTitle(item)}
                <div
                    className="list-group-typeticket-right">
                    <div className="container-component-cell">
                        <Button
                            variant="outline-primary"
                            size="small"
                            onClick={() => this.removeCartItem(item, obj)}
                        >
                            <i className="fas fa-minus"/>
                        </Button>
                        <div
                            className="container-component-cell">
                            <Badge bg="secondary"
                                   pill>
                                <div>{renderTotalByTypeTicket(this.state.arrSeats, item, obj)}</div>
                            </Badge>
                        </div>
                        <Button
                            variant="outline-primary"
                            size="small"
                            onClick={() => this.addCartItem(item, obj)}
                        >
                            <i className="fas fa-plus"/>
                        </Button>
                    </div>
                    {renderTotalPriceByTypeTicket(this.state.arrSeats, item, obj)}
                </div>
            </div>
        }
        return null;

    };

    renderTypeTitle(item){
        if (typeof (item.titleType) !== 'undefined' && item.titleType !== null){
            const arr = Object.keys(TYPE_TITLE).filter(z => z.toString() === item.titleType.toString());
            let strTypeTitle = item.titleType.toString();
            if (arr.length > 0){
                let str = arr[0].toString();
                strTypeTitle = TYPE_TITLE[str];
            }
            return <div
                className="fs-20 list-group-typeticket-left">{strTypeTitle} {typeof (item.price) !== 'undefined' && item.price !== null ? "(" + item.price.totalPrice.toString() + " €)" : ""}</div>;
        }
        return <div
            className="fs-20 list-group-typeticket-left">{typeof (item.titleType) !== 'undefined' && item.titleType !== null ? item.titleType.toString() : typeof (item.description) !== 'undefined' && item.description !== null ? item.description.toString() : ""} {typeof (item.price) !== 'undefined' && item.price !== null ? "(" + item.price.totalPrice.toString() + " €)" : ""}</div>;
    }

    render() {
        const { msgError, isLoaded, totItems, event, reply, items} = this.state;

        if (event !== null)

        if (msgError) {
            return <div>Errore: {msgError}</div>;
        } else if (!isLoaded) {
            return <div><Spinner animation="border" role="status" variant="primary" >
                <span className="visually-hidden">Caricamento...</span>
            </Spinner></div>;
        }else {
            return(
                <>
                    <div>
                        <div className="form-card">
                            <div className='align-two-text-flex'>
                                <h2 className="fs-title">{t("sectors")}</h2>
                                <h3 className="fs-title-secondary-18 text-color-secondary mt-">Totale: {this.state.totalAmount} €</h3>
                            </div>
                            <ListGroup>
                                {reply.availability.bySeatingOrders.map((objItem, index) => {
                                    return <div key={index}>
                                        <ListGroup.Item as="li"
                                                        className="d-flex justify-content-between align-items-start">
                                            <div
                                                className="header-list-group-typeticket">
                                                <div className="mb-5">
                                                    <div
                                                        className="fw-bold">{objItem.name.it.toString()}</div>
                                                    {
                                                        typeof event.genre !== 'undefined' && event.genre !== null && arr_code_genre_exclude.find(z => z.code.toString() === event.genre.code.toString())
                                                            ? null
                                                            :
                                                                objItem.availability > 0
                                                                    ?(
                                                                        <p className="size-value-row size-inline-row-150 text-success">{t("available")}</p>
                                                                    )
                                                                    : <p className="size-value-row size-inline-row-150 text-danger">{t("no_availability")}</p>
                                                    }
                                                </div>
                                                <div>
                                                    <div>
                                                        {items.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    {this.renderTypeTicketSection(item, objItem)}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </div>
                                })}
                            </ListGroup>
                            <p className="title-notice">* Puoi selezionare fino ad un max di {this.state.maxNumTicket.toString()} biglietto/i fra tutti i settori</p>
                        </div>
                    </div>
                </>
            );
        }


    }

}
