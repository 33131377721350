import React, { Component } from "react";
import {Link} from "react-router-dom";
import '../../../I18n/i18n';
import {t} from "i18next";

export default class Success extends Component {

    render() {
        return (
            <fieldset>
                <div className="form-card">
                    <h2 className="fs-title text-center">{t("congratulations")} </h2> <br />
                    <br />
                    <div className="dummy-positioning d-flex">
                        <div className="success-icon">
                            <div className="success-icon__tip"></div>
                            <div className="success-icon__long"></div>
                        </div>
                    </div>
                    <section className="section-area page-success-subscription">
                        <div className="container">
                            <div className="inner-content">
                                <h3 className="fs-title text-center margin-top-title-success">{t("purchase_completed_successfully")}</h3>
                                <h6 className="fs-title-success-subscription">{t("verify_your_email")}</h6>
                                <div className="clearfix">
                                    <Link to="/" className="btn btn-primary">Home</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </fieldset>
        );
    }
}
