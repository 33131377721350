import React, { Component } from 'react';
import '../../I18n/i18n';
import {t} from "i18next";

class InfoSection extends Component{
	render(){
		return(
			<>

				<section className="section-area section-sp5 info-area">
					<div className="container-sm">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-secondary">{t("title_info")}</h6>
							<h2 className="title">{t("subtitle_info")}</h2>
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="info-bx">
									<div className="info-num-bx">01</div>
									<div className="info-content">
										<h5 className="title text-primary mb-10">{t("title_card_info_1")}</h5>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="info-bx">
									<div className="info-num-bx">02</div>
									<div className="info-content">
										<h5 className="title text-primary mb-10">{t("title_card_info_2")}</h5>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="info-bx">
									<div className="info-num-bx">03</div>
									<div className="info-content">
										<h5 className="title text-primary mb-10">{t("title_card_info_3")}</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

			</>
		);
	}
}

export default InfoSection;
