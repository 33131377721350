//user
export const saveUser = (user) => {
    localStorage.setItem("ticaloUser", JSON.stringify(user));
};

export const getUser = () => {
    return localStorage.getItem("ticaloUser");
};

export const removeUser = () => {
    localStorage.removeItem("ticaloUser");
};

export const saveTokenUser = (token) => {
    localStorage.setItem("ticaloTokenUser", token);
};

export const getTokenUser = () => {
    return localStorage.getItem("ticaloTokenUser");
};

export const removeTokenUser = () => {
    localStorage.removeItem("ticaloTokenUser");
};


export const saveRefreshTokenUser = (token) => {
    localStorage.setItem("ticaloRefreshTokenUser", token);
};

export const getRefreshTokenUser = () => {
    return localStorage.getItem("ticaloRefreshTokenUser");
};

export const removeRefreshTokenUser = () => {
    localStorage.removeItem("ticaloRefreshTokenUser");
};

export const saveUserClientId = (id) => {
    localStorage.setItem("ticaloUserClientId", JSON.stringify(id));
};

export const getUserClientId = () => {
    return localStorage.getItem("ticaloUserClientId");
};

export const removeUserClientId = () => {
    localStorage.removeItem("ticaloUserClientId");
};


//general
export const removeStorageMap = () => {
    localStorage.removeItem('selectedSeats');
    localStorage.removeItem('mapEvent');
    localStorage.removeItem('categoriesEvent');
    localStorage.removeItem('seatsAvailableEvent');
    localStorage.removeItem('lockedSeats');
    localStorage.removeItem('seatTemp');
};
