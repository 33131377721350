import React, { useEffect, useState, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Seat from "./Seat";
import { useWindowSize } from './windowSize';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import FilterDropdown from "./FilterDropdown";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {t} from "i18next";
import {Form} from "react-bootstrap";
import { useImperativeHandle } from 'react';

//general
import {arr_error_code, EnumPrenotation, strings_general_App, TYPE_TITLE} from "../../../constants";
import {getUserClientId, removeStorageMap, saveUserClientId} from "../../../LocalStorage/storage";
import {calculateTotal} from "../../../General";
import Functions from "../../../Functions";
import seat from "./Seat";

//SeatMap, component for managing the interactive map
const SeatMap = (props) => {

	const {
		handleHover,
		checkIsSelected,
		checkAvailability,
		checkAvailabilityStatus,
		checkAvailabilityLocked,
		mapData,
		selectedSeats,
		lockedSeats,
		handleSelect,
		handleDeselect,
		findColorByCategory,
		selectedFilter
	} = props;

	useEffect(() => {

	}, [])

	const renderCell = (cell, i, total, seat_rows_number, seat, row) => {

		if (!seat) {
			return null;
		}
		cell = Number(cell);
		const isIBSRow = () => String(row?.seats[0]?.seatNumber).length <= 3;

		return <>

			<div>
				<Seat
					lengthSeat={seat.seatNumber.length}
					key={seat.seatGuid}
					seatId={seat.seatGuid}
					status={checkAvailability(seat)}
					statusPrenotation={checkAvailabilityStatus(seat)}
					statusLocked={checkAvailabilityLocked(seat)}
					seat={seat}
					rowNumber={seat_rows_number}
					onSelect={handleSelect}
					onHover={handleHover}
					position={seat.position}
					isIBSRow={isIBSRow}
					onDeselect={handleDeselect}
					isSelected={checkIsSelected(seat, selectedSeats)}
					color={findColorByCategory(seat.category)}
				/>
			</div>
		</>
	};

	const renderCategory3Row = (rows) => {
		let seats = rows.seats;
		let seatRowNumber = rows.rowNumber;
		const position = rows.position;
		const isIBSRow = () => String(seats[0]?.seatNumber).length <= 3;
		return (
			<div className="seats_row" style={{
				position: 'absolute',
				top: position.y + 3,
				left: !isIBSRow() ? position.x : position.x - 5
			}}>
				{seats.map((seat, i) =>
					renderCell(
						seat?.seatNumber,
						i,
						seats.length,
						seatRowNumber,
						seat,
						rows
					)
				)}
			</div>
		);
	};

	const renderCategorySeats = () => {
		const map = mapData;
		if (selectedFilter !== 'All') {
			const filteredZone = map.zones.find(z => z.name === selectedFilter);
			return (
				<>
					{
						filteredZone.rows.map(row =>
							renderCategory3Row(row)
						)
					}
				</>
			)
		}
		return (
			<>
				{map.zones.map(zone => (
					zone.rows.map(row =>
						renderCategory3Row(row)
					)
				))}
			</>
		);
	};

	if (!mapData) return null;

	return (
		<div className="map_container" style={{ width: mapData.size.width, height: mapData.size.height, position: 'relative' }}>
			{renderCategorySeats()}
			{mapData.zones.map(zone => (
				zone.areas.map(area => (
					area.shape === 'text' ? <div style={{ position: 'absolute', left: area.position.x, top: area.position.y, transform: `rotate(${area.rotation}deg)` }}>
						<p className="category_heading" style={{ position: 'absolute', left: area.text.position.x - 5, top: area.text.position.y, color: area.text.color, fontSize: area.text.size }}>
							{area.text.text}</p></div> : area.shape === "rectangle" ? <div className="category_color" style={{ position: 'absolute', left: area.position.x, top: area.position.y + 10.0, width: area.rectangle.width, height: area.rectangle.height, backgroundColor: area.color, border: `1px solid ${area.border_color}`, transform: `rotate(${area.rotation}deg)`  }}>
						{area.text.text ? <p style={{ color: area.text.color, fontSize: area.text.size, textAlign: "center"}}>{area.text.text}</p> : null}
					</div> : null
				))
			))}
		</div>
	);

};


const ZoomContent = (props) => {

	const {width} = useWindowSize();
	const [popup, setPopup] = useState(null);
	const [mapData, setMapData] = useState(null);
	const [selectedSeats, setSelectedSeats] = React.useState([]);
	const [availableSeats, setAvailableSeats] = React.useState([]);
	const [categories, setCategories] = React.useState([]);
	const [arrLockSeats, setArrLockSeats] = useState([]);
	const [seatingOrders, setSeatingOrders] = useState(null);

	const [isLoaded, setLoaded] = useState(false);
	const [msgError, setError] = useState("");
	const [totalAmount, setTotalAmount] = useState(0.0);
	const [totalSeat, setTotalSeat] = useState(0);
	const [cartPositionsList, setCartPositionsList] = React.useState([]);
	const [showModal, setShowModal] = useState(false);
	const [msgModal, setMsgModal] = useState("");
	const [checkedPresale, setCheckedPresale] = useState(false);
	const [maxNumTicket, setMaxNumTicket] = useState(0);

	const [lockedSeat, setLockedSeat] = useState(null);
	const [unlockedSeat, setUnLockedSeat] = useState(null);

	const [socketStatus, setSocketStatus] = useState('closing');
	const [seatTemp, setSeatTemp] = useState(null);
	const [seatTempUnlock, setSeatTempUnlock] = useState(null)
	const wsRef = useRef();

	useEffect(() => {

		const map = JSON.parse(localStorage.getItem("mapEvent"));
		let categoriesLocal = [];
		let ws = null;
		if (typeof (map) !== 'undefined' && map !== null) {
			setLockedSeat(null);
			setUnLockedSeat(null);
			setLoaded(true);
			categoriesLocal = JSON.parse(localStorage.getItem("categoriesEvent"));
			setCategories(categoriesLocal);
			const seatsAvailable = JSON.parse(localStorage.getItem("seatsAvailableEvent"));
			setAvailableSeats(seatsAvailable);
			setMapData(map);
			const selectedSeats = JSON.parse(localStorage.getItem("selectedSeats"));
			if (typeof (selectedSeats) !== 'undefined' && selectedSeats !== null) {
				setSelectedSeats(selectedSeats);
				setTotal(selectedSeats);
				props.seatsSelection(selectedSeats)
			}
			const newLockedSeats = JSON.parse(localStorage.getItem("lockedSeats"));
			if (typeof (newLockedSeats) !== 'undefined' && newLockedSeats !== null) {
				setArrLockSeats(newLockedSeats);
			}
			if (typeof (props.values.wsRef) !== 'undefined' && props.values.wsRef !== null) {
				if (typeof (wsRef.current) === 'undefined' || wsRef.current === null) {
					wsRef.current = props.values.wsRef.current;
					props.setWSRef(wsRef.current);
					setSocketStatus('opening')
				}
			}
			/*if (typeof (props.checkedPresale) !== 'undefined' && props.checkedPresale !== null) {
				setCheckedPresale(props.checkedPresale);
				props.setPresale(props.checkedPresale);
			}*/
		}else{
			loadMap();
		}

		window.onbeforeunload = function(event) {
			wsRef.current = null;
			setLockedSeat(null);
			setUnLockedSeat(null);
			removeStorageMap();
		};

		if (typeof (props.typeTicketDefault) !== 'undefined' && props.typeTicketDefault !== null) {
			let categoriesForTypeTicket = categories;
			if (categoriesForTypeTicket.length === 0){
				categoriesForTypeTicket = categoriesLocal;
			}
			const objCategory = categoriesForTypeTicket.find(c => c.code.toString() === props.typeTicketDefault.seatingOrder.toString());
			if (typeof (objCategory) !== 'undefined' && objCategory !== null){
				setFilter(objCategory.name.toString());
			}else{
				setFilter("All");
			}
		}else{
			setFilter("All");
		}

		if(typeof (lockedSeat) !== 'undefined' && lockedSeat !== null){
			setArrLockSeats([...arrLockSeats, lockedSeat]);
			setLockedSeat(null);
		}

		if(typeof (unlockedSeat) !== 'undefined' && unlockedSeat !== null){
			const newUnLockedSeats = arrLockSeats.slice();
			const selectedItem = newUnLockedSeats.find(element => element?.seatGuid === unlockedSeat.seatGuid);
			if (typeof (selectedItem) !== 'undefined' && selectedItem !== null){
				newUnLockedSeats
					.splice(newUnLockedSeats.indexOf(selectedItem), 1);
				localStorage.removeItem("lockedSeats");
				localStorage.setItem("lockedSeats", JSON.stringify(newUnLockedSeats));
				loadLockedSeats(newUnLockedSeats);
			}
			setUnLockedSeat(null);
		}
		if(typeof (seatTemp) !== 'undefined' && seatTemp !== null){
			const newSeats = [...selectedSeats, seatTemp];
			setSelectedSeats(newSeats);
			localStorage.removeItem("selectedSeats");
			localStorage.setItem("selectedSeats", JSON.stringify(newSeats));
			setTotal(newSeats);
			props.seatsSelection(newSeats);
			setSeatTemp(null);
		}

		if(typeof (seatTempUnlock) !== 'undefined' && seatTempUnlock !== null){
			let selectedSeatsTemp = JSON.parse(localStorage.getItem("selectedSeats"));
			if (typeof (selectedSeatsTemp) !== 'undefined' || selectedSeatsTemp !== null && selectedSeatsTemp.length > 0) {
				const newSeats = selectedSeatsTemp.slice();
				const selectedItem = newSeats.find(element => element?.seatGuid === seatTempUnlock?.seatGuid);
				if (typeof (selectedItem) !== 'undefined' && selectedItem !== null){
					newSeats.splice(newSeats.indexOf(selectedItem), 1);
					setSelectedSeats(newSeats);
					localStorage.removeItem("selectedSeats");
					localStorage.setItem("selectedSeats", JSON.stringify(newSeats));
					setTotal(newSeats);
					props.seatsSelection(newSeats);
				}
			}

			const lockedSeats = JSON.parse(localStorage.getItem("lockedSeats"));
			if (typeof (lockedSeats) !== 'undefined' && lockedSeats !== null && lockedSeats.length > 0){
				const newUnLockedSeats = lockedSeats.slice();
				const selectedItem = newUnLockedSeats.find(element => element?.seatGuid === seatTempUnlock.seatGuid);
				if (typeof (selectedItem) !== 'undefined' && selectedItem !== null){
					newUnLockedSeats
						.splice(newUnLockedSeats.indexOf(selectedItem), 1);
					localStorage.removeItem("lockedSeats");
					localStorage.setItem("lockedSeats", JSON.stringify(newUnLockedSeats));
					loadLockedSeats(newUnLockedSeats);
				}
			}
			setSeatTempUnlock(null);
		}

		if (socketStatus === 'closing') {
			connectSocket(ws);
			setSocketStatus('opening')
		}

	}, [props.maxNumTicket, checkedPresale, lockedSeat, unlockedSeat, seatTemp, seatTempUnlock, wsRef, socketStatus])

	/*const actionLoadLockedSeat = React.useCallback((seat) => {
		console.log("LOAD LOCKED 1 SEAT");

		setTimeout(()=>{
			let clientID = JSON.parse(getUserClientId());
			console.log(seat.lockedBy)
			console.log(clientID)
			console.log(seat)
			if (seat.lockedBy.toString() === clientID){
				if (typeof (seatT) !== 'undefined' && seatT !== null && typeof (seatT.current) !== 'undefined' && seatT.current !== null){
					let seatSelected = seatT.current;
					console.log(seatSelected)
					const typeTicket = props.items.filter(item => item.nameCategory.includes(seatSelected.category.toString()));
					if(typeof (typeTicket) !== 'undefined' && typeTicket !== null && typeTicket.length > 0){
						seatSelected.typeTicket = typeTicket[0];
					}else{
						seatSelected.typeTicket = props.items[0];
					}
					let selectedSeatsTemp = JSON.parse(localStorage.getItem("selectedSeats"));
					if (typeof (selectedSeatsTemp) === 'undefined' || selectedSeatsTemp === null) {
						selectedSeatsTemp = [];
					}
					const newSeats = selectedSeatsTemp.concat([seatSelected])
					props.seatsSelection(newSeats)
					setSelectedSeats(newSeats);
					localStorage.removeItem("selectedSeats");
					props.handleChange?.("selectedSeats");
					localStorage.setItem("selectedSeats", JSON.stringify(newSeats));
					setTotal(newSeats);
				}
			}else{
				const lockedSeats = JSON.parse(localStorage.getItem("lockedSeats"));
				const newLockedSeats = lockedSeats.concat([seat]);
				localStorage.setItem("lockedSeats", JSON.stringify(newLockedSeats));
				loadLockedSeats(newLockedSeats);
			}
		},150)
	}, [selectedSeats]);

	const actionUnloadLockedSeat = React.useCallback((seat) => {
		console.log("LOAD UNLOCKED 1 SEAT");

		let selectedSeatsTemp = JSON.parse(localStorage.getItem("selectedSeats"));
		if (typeof (selectedSeatsTemp) !== 'undefined' || selectedSeatsTemp !== null && selectedSeatsTemp.length > 0) {
			const newSeats = selectedSeatsTemp.slice();
			const selectedItem = newSeats.find(element => element?.seatGuid === seat?.seatGuid);
			if (typeof (selectedItem) !== 'undefined' && selectedItem !== null){
				newSeats.splice(newSeats.indexOf(selectedItem), 1);
				setSelectedSeats(newSeats);
				props.seatsSelection(newSeats);
				localStorage.removeItem("selectedSeats");
				localStorage.setItem("selectedSeats", JSON.stringify(newSeats));
				setTotal(newSeats);
			}
		}

		const lockedSeats = JSON.parse(localStorage.getItem("lockedSeats"));
		if (typeof (lockedSeats) !== 'undefined' && lockedSeats !== null && lockedSeats.length > 0){
			const newUnLockedSeats = lockedSeats.slice();
			const selectedItem = newUnLockedSeats.find(element => element?.seatGuid === seat.seatGuid);
			if (typeof (selectedItem) !== 'undefined' && selectedItem !== null){
				newUnLockedSeats
					.splice(newUnLockedSeats.indexOf(selectedItem), 1);
				localStorage.removeItem("lockedSeats");
				localStorage.setItem("lockedSeats", JSON.stringify(newUnLockedSeats));
				loadLockedSeats(newUnLockedSeats);
			}
		}

	}, [selectedSeats]);*/

	const actionLockedSeats = React.useCallback((arr) => {
		if (typeof (arr) !== 'undefined' && arr !== null) {
			let clientID = JSON.parse(getUserClientId());
			if (arr.length > 0){
				localStorage.setItem("lockedSeats", JSON.stringify(arr));
				const item = arr.find(element => element.lockedBy === clientID);
				if (typeof (item) !== 'undefined' && item !== null) {
					loadLockedSeats(arr);
				}else{
					if (selectedSeats.length === 0){
						loadLockedSeats(arr);
					}
				}
			}
		}
	}, []);

	const loadLockedSeats = React.useCallback((arr) => {
		setTimeout(()=>{
			setArrLockSeats(arr);
		},250)
	}, [arrLockSeats]);


	const connectSocket = (ws) => {

		if (wsRef.current) return;

		let socket = new WebSocket(strings_general_App.WS_URL);

		/*if (ws === null){
			socket = ws;
		}else{
			socket.onopen = () => {
				console.log('connected');
				socket.send(JSON.stringify({
					lockingMechanism: 'challenge-response',
					eventId: props.dataReply.id.toString()
				}));
			};
		}*/

		socket.onopen = () => {
			console.log('connected');
			socket.send(JSON.stringify({
				lockingMechanism: 'challenge-response',
				eventId: props.dataReply.id.toString()
			}));
		};

		let errorCustom = false;

		socket.onmessage = (e) => {
			const response = JSON.parse(e.data);
			if (typeof (response.error) !== 'undefined' && response.error !== null){
				let errorMsg = response.error.toString();
				if (typeof (response.code) !== 'undefined' && response.code !== null){
					let errorCode = response.code.toString();
					const obj = arr_error_code.find(c => c.name.toString() === errorCode);
					if (obj !== null){

						errorMsg = t(errorCode);
						setArrLockSeats([]);
						errorCustom = true;
						props.resetTimer();

						setTimeout(() => {
							socket.close();
							setSocketStatus("closing");
							props.showModalComponent(true, t("MsgConfigurationPrenotation"));
						}, 300);
						setTimeout( () => {
							props.showModalComponent(false, "");
							props.history.goBack();
						}, 3500);

					}
				}
				if (!errorCustom){
					setTimeout( () => {
						Functions.alertMsg(errorMsg);
					}, 300);
				}
			}
			if (typeof (response.clientId) !== 'undefined' && response.clientId !== null){
				saveUserClientId(response.clientId.toString());
			}
			if (typeof (response.lockedSeats) !== 'undefined' && response.lockedSeats !== null && response.lockedSeats.length > 0){
				props.showModalComponent(false, "");
				actionLockedSeats(response.lockedSeats);
			}
			/*if (typeof (response.lockedSeat) !== 'undefined' && response.lockedSeat !== null){
				console.log("lockedSeat present")
				props.showModalComponent(false, "");
				setTimeout(() => {
					if (response.lockedSeat.status === EnumPrenotation.PAID || response.lockedSeat.status === EnumPrenotation.LOCKED || response.lockedSeat.status === EnumPrenotation.CREATED
						|| response.lockedSeat.status === EnumPrenotation.BOOKED || response.lockedSeat.status === EnumPrenotation.BLOCKED || response.lockedSeat.status === EnumPrenotation.SELECTED){
						actionLoadLockedSeat(response.lockedSeat);
					}
				}, 100);
			}
			if (typeof (response.unlockedSeat) !== 'undefined' && response.unlockedSeat !== null){
				console.log("unlockedSeat present")
				props.showModalComponent(false, "");
				setTimeout(() => {
					if (response.unlockedSeat.status === EnumPrenotation.UNLOCKED){
						actionUnloadLockedSeat(response.unlockedSeat);
					}
				}, 100);
			}*/

			if (typeof (response.lockedSeat) !== 'undefined' && response.lockedSeat !== null) {
				console.log("response.lockedSeat: ", response.lockedSeat)
				if (response.lockedSeat.status === EnumPrenotation.PAID || response.lockedSeat.status === EnumPrenotation.LOCKED || response.lockedSeat.status === EnumPrenotation.CREATED
					|| response.lockedSeat.status === EnumPrenotation.BOOKED || response.lockedSeat.status === EnumPrenotation.BLOCKED || response.lockedSeat.status === EnumPrenotation.SELECTED){
					let clientID = JSON.parse(getUserClientId());
					console.log("clientID: ", clientID)
					props.showModalComponent(false, "");
					if (response.lockedSeat.lockedBy === clientID) {
						setLockedSeat(null);
						setUnLockedSeat(null);
					}else{
						setLockedSeat(response.lockedSeat);
						let seat = response.lockedSeat;
						const lockedSeats = JSON.parse(localStorage.getItem("lockedSeats"));
						const newLockedSeats = lockedSeats.concat([seat]);
						localStorage.setItem("lockedSeats", JSON.stringify(newLockedSeats));
						loadLockedSeats(newLockedSeats);
						setUnLockedSeat(null);
					}
				}
			}
			if (typeof (response.unlockedSeat) !== 'undefined' && response.unlockedSeat !== null) {
				console.log("response.unlockedSeat: ", response.unlockedSeat)
				setUnLockedSeat(response.unlockedSeat);
				setLockedSeat(null);
				props.showModalComponent(false, "");
			}

			if (typeof (response.challenge) !== 'undefined' && response.challenge !== null){
				props.showModalComponent(false, "");
				challengeSolver(response.challenge, socket);
			}
		};

		socket.onclose = function (e) {
			wsRef.current.close();
			wsRef.current = null;
			setTimeout(() => {
				setSocketStatus('closing');
			}, 300);
		};

		socket.onerror = (err) => {
			setArrLockSeats([]);
			errorCustom = true;
			props.resetTimer();
			setLockedSeat(null);
			setUnLockedSeat(null);
			setArrLockSeats([]);
			setTimeout(() => {
				wsRef.current.close();
				setSocketStatus("closing");
				Functions.alertMsg(t("msgErrorServer"));
			}, 300);
			setTimeout(async () => {
				props.history.goBack();
			}, 500);

		};

		wsRef.current = socket;
		props.setWSRef(wsRef.current);

		return () => {
			wsRef.current.close();
			setLockedSeat(null);
			setUnLockedSeat(null);
			setArrLockSeats([]);
			setTimeout(() => {
				setSocketStatus('closing')
			}, 300);
		};

	};

	let challengeSolver = (challenge, socketToReplyOn, timeout = 5000) => {
		setTimeout(async () => {
			const toFind = challenge.goalBytes;
			const inPos = challenge.goalPositions;

			let i = 0;
			let arrLen = Math.ceil(Math.log2(i + 1) / 8) || 1;
			let bArr = new Uint8Array(arrLen);
			let timeoutReached = false;
			let found = false;
			const timeoutId = setTimeout(() => timeoutReached = true, timeout);
			while (!timeoutReached && !found) {
				let newArrLen = Math.ceil(Math.log2(i + 1) / 8) || 1;
				if (newArrLen !== arrLen) {
					arrLen = newArrLen;
					bArr = new Uint8Array(arrLen);
				}
				for (let j = 0; j < arrLen; j++) {
					bArr[j] = (i >> (arrLen - j - 1) * 8) & 0xff;
				}

				const hashBuffer = await crypto.subtle.digest('SHA-256', bArr);
				const hashArray = Array.from(new Uint8Array(hashBuffer));

				for (let j = 0; j < toFind.length; j++) {
					if (hashArray[inPos[j]] !== toFind[j]) {
						break;
					}
					if (j === toFind.length - 1) {
						clearTimeout(timeoutId);
						found = true;
					}
				}
				i++;
			}
			socketToReplyOn.send(JSON.stringify({
				response: (found ? (() => {
					const arr = [];
					for (let i = 0; i < bArr.length; i++) {
						arr.push(bArr[i]);
					}
					return arr;
				})() : false)
			}));
		}, 0);
	};

	const loadMap = React.useCallback(() => {
		setTimeout(()=>{
			const arr = JSON.parse(localStorage.getItem("selectedSeats"));
			if (typeof (arr) !== 'undefined' && arr !== null && arr.length > 0) {
				setSelectedSeats(arr);
				setTotal(arr);
			}
			localStorage.setItem("mapEvent", JSON.stringify(props.dataReply.location.seatingPlan));
			localStorage.setItem("categoriesEvent", JSON.stringify(props.dataReply.location.seatingOrders));
			localStorage.setItem("seatsAvailableEvent", JSON.stringify(props.dataReply.availability.bySeatingOrders));
			if (typeof (props.lockedSeats) !== 'undefined' && props.lockedSeats !== null && props.lockedSeats.length > 0) {
				localStorage.setItem("lockedSeats", JSON.stringify(props.lockedSeats));
			}
			setLoaded(true);
			if (typeof (props.dataReply) !== 'undefined' && props.dataReply !== null) {
				if (typeof (props.dataReply.location.seatingOrders) !== 'undefined' && props.dataReply.location.seatingOrders !== null) {
					setCategories(props.dataReply.location.seatingOrders);
				}

				if (typeof (props.dataReply.location.seatingPlan) !== 'undefined' && props.dataReply.location.seatingPlan !== null) {
					setMapData(props.dataReply.location.seatingPlan);
				}

				if (typeof (props.dataReply.location.seatingOrders) !== 'undefined' && props.dataReply.location.seatingOrders !== null) {
					setSeatingOrders(props.dataReply.location.seatingOrders);
				}

				if (typeof (props.dataReply.availability.bySeatingOrders) !== 'undefined' && props.dataReply.availability.bySeatingOrders !== null) {
					setAvailableSeats(props.dataReply.availability.bySeatingOrders);
				}
			}

		},100)
	}, [props.dataReply.availability.bySeatingOrders,
		props.dataReply.location.seatingOrders, props.dataReply.location.seatingPlan, props.dataReply.location.seatingOrders]);

	const findColorByCategory = category => {
		const findMatched = categories.find(c => c.name === category);
		return findMatched?.color;
	};

	const handleSelect = React.useCallback(
		 seat => {
			 if (selectedSeats.length < props.maxNumTicket) {
				 const typeTicket = props.items.filter(item => item.nameCategory.includes(seat.category.toString()));
				 if (typeof (typeTicket) !== 'undefined' && typeTicket !== null && typeTicket.length > 0) {
					 seat.typeTicket = typeTicket[0];
				 } else {
					 seat.typeTicket = props.items[0];
				 }
				 props.showModalComponent(true, t("lock_seat"));
				 setSeatTemp(seat);
				 wsRef.current.send(JSON.stringify({
					 "seatToLock": seat.seatGuid
				 }))
			 } else {
				 Functions.alertMsg(t("max_ticket_selected"))
			 }
		},
		[selectedSeats]
	);

	const handleDeselect = React.useCallback(
		seat => {
			props.showModalComponent(true, t("unlock_seat"));
			setSeatTempUnlock(seat);
			wsRef.current.send(JSON.stringify({
				seatToUnlock: seat?.seatGuid
			}));
			if (typeof (props.values) !== 'undefined' && props.values !== null) {
				if (typeof (props.values.arrUsers) !== 'undefined' && props.values.arrUsers !== null && props.values.arrUsers.length > 0) {
					let arr = props.values.arrUsers;
					const el = arr.find(item => item.seatGuid === seat.seatGuid);
					if (typeof (el) !== 'undefined' && el !== null) {
						arr.splice(arr.indexOf(el), 1);
					}
					if (arr.length > 0){
						props.setArrUser(arr);
					}
				}
			}
		},
		[selectedSeats, totalAmount, totalSeat]
	);

	const checkAvailability = (seat) => {
		if (arrLockSeats.length === 0){
			return true;
		}
		return arrLockSeats.find(s => s.seatGuid === seat.seatGuid) ? false : true;
	};

	const checkAvailabilityStatus = (seat) => {
		if (arrLockSeats.length === 0){
			return "";
		}
		let arr = arrLockSeats.find(s => s.seatGuid === seat.seatGuid);
		if (typeof (arr) !== 'undefined' && arr !== null) {
			return arr.status
		}
		return "";
	};

	const checkAvailabilityLocked = (seat) => {
		if (arrLockSeats.length === 0){
			return "";
		}
		let arr = arrLockSeats.find(s => s.seatGuid === seat.seatGuid);
		if (typeof (arr) !== 'undefined' && arr !== null) {
			return arr
		}
		return null;
	};

	const checkIsSelected = (seat, selectedSeats) => {
		return selectedSeats?.find(s => s.seatGuid === seat.seatGuid) ? true : false;
	};

	const renderSectorBySeat = (mapData, seat) => {

		let nameZone = "";
		let colorCategory = "";

		if (mapData !== null){
			for (let z=0; z < mapData.zones.length; z++) {
				for (let i=0; i < mapData.zones[z].rows.length; i++) {
					let rowSeats = mapData.zones[z].rows[i].seats;
					if (rowSeats.length > 0){
						for (let j=0; j < rowSeats.length; j++) {
							let seatGuid = rowSeats[j].seatGuid;
							if (seatGuid === seat.seatGuid){
								nameZone = mapData.zones[z].name;
							}

						}
					}
				}
			}
			for (let x=0; x < categories.length; x++) {
				let nameCategory = categories[x].name;
				if (nameCategory === seat.category){
					colorCategory = categories[x].color;
				}
			}
		}

		if (nameZone !== ""){
			return <div className="display-flex-container">
				<div className="dot-category" style={{backgroundColor: colorCategory}} />
				<p className="title3-modal-event  text-color-primary ml-10 mt-3-neg">Settore&nbsp;{nameZone}</p>
			</div>
		}

	};

	const deleteSeat = (e, seat) => {
		e.preventDefault();
		e.stopPropagation();
		handleDeselect(seat)
	};

	const setItemToSeat = (e, seat, arrType) => {
		if (typeof (e.target.selectedIndex) !== 'undefined' && e.target.selectedIndex !== null) {
			seat.typeTicket = arrType[e.target.selectedIndex];
			localStorage.setItem("selectedSeats", JSON.stringify(selectedSeats));
			setTotal(selectedSeats);
		}
		e.preventDefault();
		e.stopPropagation();
	};

	const setTotal= (seats) =>{
		let total = calculateTotal(seats, checkedPresale);
		setTimeout(()=>{
			setTotalAmount(total);
		},100)
	};

	/*const setPresale= (e) => {
		const checked = e.target.checked;
		setCheckedPresale(checked);
		setTotal(selectedSeats);
		props.setPresale(checked);
		e.stopPropagation();
	};*/

	/*const checkAvailableMap = () => {
		return null;
		if (mapData !== null){
            let arr = availableSeats;
            let dict = [];
            for (var i=0; i < Object.keys(arr).length; i++) {
                let key = Object.keys(arr)[i];
                let arrTemp = arr[key];
                let seatList = arrTemp['seatList'];
                if (seatList.length > 0){
                    for (var j=0; j < seatList.length; j++) {
                        dict.push(seatList[j])
                    }
                }
            }
            return <div>
                {dict.length < 1 ? (
                    <div className="overlay">
                        <div className="overlay-wrapper">
                            <div className="overlay-text">Posti non disponibili</div>
                        </div>
                    </div>
                ) : null}
            </div>
        }else{
            return null;
        }
	};*/

	const handleHover = React.useCallback((seat, pos) => {
		setPopup({
			seat: seat,
			position: pos
		});
	}, []);


	const renderSeatsSelected = () => {
		if (selectedSeats.length > 0){
			let arr = selectedSeats.slice().reverse();
			return <div>
				<div className="divider" />
				{arr.map((seat) => (
					<Row className="justify-content-md-center row">
						<Col className="col" screen_size={6}>
							<div className='align-two-text-flex'>
								<p>{seat.category}</p>
								<i className="icons-table-users fa fa-trash text-danger cursor-pointer-text" onClick={(e) => deleteSeat(e, seat)} />
							</div>
							<div className='align-two-text-flex'>
								<p className="title1-modal-event">{t("row")}&nbsp;<p className="title2-modal-event">{seat.row_number}</p></p>
								<p className="title1-modal-event">{t("seat")}&nbsp;<p className="title2-modal-event">{seat.seatNumber}</p></p>
							</div>
							{renderDropboxItems(seat)}
							<div className="divider" />
						</Col>
					</Row>
				))}
			</div>
		}
	};

	const renderDropboxItems = (seat) => {
		if (typeof (seat.typeTicket) !== 'undefined' && seat.typeTicket !== null) {
			let typeTicket = seat.typeTicket.seatingOrder.toString();
			if (typeof (props.items) !== 'undefined' && props.items !== null && props.items.length > 0) {
				let arrType = props.items.filter(z => z.seatingOrder.toString() === typeTicket);
				return <Form.Select onChange={(e) => setItemToSeat(e, seat, arrType)}>
					{arrType.map((item, idx) => (
						renderOption(item, idx, seat)
					))}
				</Form.Select>
			}else{
				return null;
			}
		}
		return null;
	};

	const renderOption = (obj, idx, seat) => {
		let check = false;
		if (props.event.type === "event"){
			if (seat.typeTicket.seatingOrder.toString() === obj.seatingOrder.toString() && seat.typeTicket.titleType.toString() === obj.titleType.toString()){
				check = true;
			}
		}else{
			if (seat.typeTicket.seatingOrder.toString() === obj.seatingOrder.toString() && seat.typeTicket.description.toString() === obj.description.toString()){
				check = true;
			}
		}
		let strTypeTitle = "";
		if (typeof obj.titleType !== 'undefined' && obj.titleType !== null){
			const arr = Object.keys(TYPE_TITLE).filter(z => z.toString() === obj.titleType.toString());
			if (arr.length > 0){
				let str = arr[0].toString();
				strTypeTitle = TYPE_TITLE[str];
			}
		}else{
			strTypeTitle = obj.description.toString();
		}
		return <option selected={check} key={idx}>{obj.seatingOrder.toString()} - {strTypeTitle} ({obj.price.totalPrice.toString()} €)</option>
		//return <option selected={check} key={idx}>{obj.seatingOrder.toString()} - {typeof obj.titleType !== 'undefined' && obj.titleType !== null ? obj.titleType.toString() : obj.description.toString()} ({obj.price.grossPrice.toString()} € {typeof obj.price.grossFees !== 'undefined' && obj.price.grossFees !== null ? ", " + t("presale") + ": " + obj.price.grossFees.toString() + " €" : ""})</option>
	};

	const iWidth = width ? width : window.innerWidth;
	const [showSlidebar, setShowSlidebar] = useState(false);
	const handleCloseSlidebar = () => setShowSlidebar(false);
	const handleShowSlidebar = () => setShowSlidebar(true);
	const [showSlidebarEvents, setShowSlidebarEvents] = useState(false);
	const handleCloseSlidebarEvents = () => setShowSlidebarEvents(false);
	const handleShowSlidebarEvents = () => setShowSlidebarEvents(true);

	const [presenseDropdown, setPresenseDropdown] = useState(false);
	const presenseDropdownRef = useRef();
	useOutsideClick(presenseDropdownRef, () => { setPresenseDropdown(false) })
	const [selectedFilter, setFilter] = useState("All")

	if (msgError) {
		return <div>Errore: {msgError}</div>;
	} else if (!isLoaded) {
		return <div><Spinner animation="border" role="status" variant="primary" >
			<span className="visually-hidden">Caricamento...</span>
		</Spinner></div>;
	}else{
		return (
			<div>
				<fieldset key="bluenet" style={{position: "relative"}}>
					{/*checkAvailableMap()*/}

					<div style={{position: 'relative', right: '70px'}} ref={presenseDropdownRef}>
						<button className="btn btn-primary shadow zoom-button filter-button" onClick={(e) => {
							e.preventDefault();
							setPresenseDropdown(!presenseDropdown);
						}}>
							<i className="fa fa-filter"/>
						</button>
						<div className={`map-filters dropdown ${presenseDropdown ? 'dropdown--visible' : ''}`}>
							<FilterDropdown
								findColorByCategory={findColorByCategory}
								zones={mapData?.zones}
								setFilter={setFilter}
								selectedZone={selectedFilter}
								typeTicketDefault={props.typeTicketDefault}
							/>
						</div>
					</div>
					<div className="form-card">
						<div className='align-two-text-flex'>
							<h2 className="fs-title">{t("total")}: {totalAmount} €</h2>
						</div>
						<div className='align-two-text-flex'>
							<Badge bg="info" className="fs-title-secondary-14 mb-10">
								<i className="fa fa-chair"/>&nbsp;
								{t("seats_selected")}: {selectedSeats.length}
							</Badge>
						</div>
						<Row>
							<Col xl={3}>
								{renderSeatsSelected()}
							</Col>
							<Col xl={9}>
								<div>
									<TransformWrapper
										//centerZoomedOut={true}
										centerOnInit={true}
										maxPositionX={window.innerWidth}
										maxPositionY={window.innerHeight - 200}
										wrapperClass={"zoom-wrapper"}
										contentClass={"zoom-wrapper"}
										// limitToBounds={false}
										initialScale={(iWidth < 1080 && iWidth > 860) ? 0.8 : (iWidth < 860 && iWidth > 500) ? 0.5 : (iWidth < 500) ? 0.35 : undefined}
										minScale={-10}
									>
										{({zoomIn, zoomOut, resetTransform, ...rest}) => (
											<React.Fragment>
												<TransformComponent wrapperStyle={{
													margin: '0 auto',
													backgroundColor: 'rgba(23, 28, 56, .7)',
													border: "2px solid black"
												}}>
													<SeatMap
														{...props}
														mapData={mapData}
														checkAvailability={checkAvailability}
														checkAvailabilityStatus={checkAvailabilityStatus}
														checkAvailabilityLocked={checkAvailabilityLocked}
														checkIsSelected={checkIsSelected}
														handleSelect={handleSelect}
														handleDeselect={handleDeselect}
														selectedSeats={selectedSeats}
														lockedSeats={arrLockSeats}
														findColorByCategory={findColorByCategory}
														selectedFilter={selectedFilter}
														handleHover={handleHover}
													/>
												</TransformComponent>
											</React.Fragment>
										)}
									</TransformWrapper>

								</div>
							</Col>
						</Row>
					</div>
				</fieldset>
			</div>
		);
	}


}

export default ZoomContent;

