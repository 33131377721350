import React from "react";
import Moment from "react-moment";
import {Row, Col, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DataManager from "../../../DataManager";
import {toast, ToastContainer} from "react-toastify";
import '../../../../I18n/i18n';
import {t} from "i18next";
import { withRouter } from 'react-router-dom'

// Images
import iconSoldOut from '../../../../images/soldout.png';
import imgPlaceholder from "../../../../images/placeholderTicalo.png";

//components
import Utils from "../../../Utils";
import Functions from "../../../Functions";
import UserClass from "../../../LocalStorage/UserClass";
import SeatMap from "../../wizard/SeatMap/SeatMap";

class Event extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            totItems: 0,
            resultDataItems: [],
            resultDataQuotas: [],
            resultDataQuotasAllEvents: [],
            resultDataAvailabilityAllEvents: [],
            statusTicketSelected: true,
            showModalInfoEvent: false,
            arrEvents: [],
            arrReplicas: [],
            event: this.props.location.state.event,
            eventSelected: this.props.location.state.event,
            organizer: this.props.location.state.organizer,
            isLoadedDates: false,
            msgErrorDates: ""
        };

    }

    componentDidMount() {

        this.setState({
            isLoadedDates: false,
        });
        let instance = UserClass.getInstance();
        let tokenUser = instance.getTokenUser();
        this.loadReplies(tokenUser);

    }

    loadReplies = (token) =>{
        DataManager.getReplicas(token, this.state.event.id.toString()).then((result) => {
            if(typeof (result) !== 'undefined' && result !== null){
                if (typeof (result['replicas']) !== 'undefined' && result['replicas'] !== null) {
                    let arr = result['replicas'].result.replicas;
                    this.setState({
                        isLoadedDates: true,
                        arrReplicas: arr
                    });
                    if (arr.length === 0){
                        this.setState({
                            msgErrorDates: t("no_dates_available")
                        });
                    }
                } else {
                    let error = t("msgErrorGeneral");
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        error = result['msgError'];
                    }
                    this.setState({
                        isLoadedDates: true,
                        msgErrorDates: error
                    });
                }
            }else{
                this.setState({
                    isLoadedDates: true,
                    msgErrorDates : t("MsgErrorDates")
                });
            }
        });
    };

    actionVisualizeEvent(e, obj) {
        e.preventDefault();
        e.stopPropagation();
        if (!obj.hasMap){
            //event without map
            this.setState({
                statusTicketSelected: true
            });
            this.props.history.push({
                pathname: '/individual',
                state: {reply: obj, event : this.state.event, organizer: this.state.organizer, typeEvent: Utils.TypeEventSingleWithoutMap, typeTicket: Utils.TicketSingle},
                search: null
            });
        }else{
            //event with map
            this.setState({
                statusTicketSelected: true
            });
            this.props.history.push({
                pathname: '/individual',
                state: {reply: obj, event : this.state.event, organizer: this.state.organizer, typeEvent: Utils.TypeEventSingleWithMap, typeTicket: Utils.TicketSingle},
                search: null
            });
        }
    }

    renderListItemDates(obj){
        if (obj.isSellable){
            if (typeof (obj.availability) !== 'undefined' && obj.availability !== null){
                if (typeof (obj.availability.total) !== 'undefined' && obj.availability.total !== null && obj.availability.total > 0){
                    return <Link to="" onClick={(e) => this.actionVisualizeEvent(e, obj)}>
                        {Functions.convertDate(obj.startDate)}
                        <i className="fa fa-angle-right" />
                    </Link>
                }else{
                    return <div className="btn-soldout">
                        {Functions.convertDate(obj.startDate)}
                        <img src={iconSoldOut} width="70" className="margin-left-soldout" alt={t("soldout_image")} />
                    </div>
                }
            }else{
                return null;
            }
        }else{
            return null;
        }
    }

    renderDates() {

        if (this.state.msgErrorDates) {
            return (
                <ul className="service-menu">
                    <h6>{t("date_available")}</h6>
                    <div className="text-danger">{this.state.msgErrorDates}</div>
                </ul>
            );
        } else if (!this.state.isLoadedDates) {
            return (
                <ul className="service-menu">
                    <h6>{t("date_available")}</h6>
                    <div><Spinner animation="border" role="status" variant="primary" >
                        <span className="visually-hidden">Caricamento...</span>
                    </Spinner></div>
                </ul>
            );
        }else{
            return (
                <ul className="service-menu">
                    <h6>{t("date_available")}</h6>
                    {this.state.arrReplicas.map((obj, i) => (
                        <li key={i.toString()}>{this.renderListItemDates(obj)}
                        </li>
                    ))}
                </ul>
            );
        }
    }

    render() {
        const {organizer, event} = this.state;

        return (
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{"zIndex": 1000000}}
                    >
                    </ToastContainer>
                </>
                <div>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp1">
                            <div className="container">
                                <Row className="row align-items-center">
                                    <Col className="mb-30 mt-20">
                                        <div className="detail-organizer-thumb-area">
                                            <img
                                                className="detail-organizer-thumb1 shadow-img-organizer img-event-detail"
                                                src={typeof (event.headerImage) !== 'undefined' && event.headerImage !== null && event.headerImage !== "" ? process.env.REACT_APP_BASE_URL_MEDIA_PUB + event.headerImage.toString() : imgPlaceholder}
                                                alt={t("image_event") + " " + event.title.toString()}/>
                                        </div>
                                    </Col>
                                    <Col className="mb-30">
                                        <div className="heading-bx">
                                            <h2 className="title">{event.title.toString()}</h2>
                                            {typeof (event.genre) !== 'undefined' && event.genre !== null && typeof (event.genre.description) !== 'undefined' && event.genre.description !== null && event.genre.description.toString() !== "" ? <h5 className="text-secondary">{event.genre.description.toString()}</h5> : null}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="container">
                                <Row>
                                    <Col lg={6} className="mb-30">
                                        <div className="clearfix">
                                            <div className="head-text mb-30">
                                                <h4 className="title mb-15">{t("description")}</h4>
                                                {typeof (event.description) !== 'undefined' && event.description !== null && event.description.toString() !== "" ? <p>{event.description.toString()}</p> : null}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <aside className="sticky-top pb-1">
                                            <div className="widget">
                                                {this.renderDates()}
                                            </div>
                                        </aside>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    </div>

                </div>
            </>
        );

    }
}


export default withRouter(Event);
