import {Col, Row} from "react-bootstrap";
import {t} from "i18next";
import React from "react";

export const calculateTotal = (seats, checkedPresale) => {
    let total = 0;
    if(seats.length > 0){
        for (let i=0; i < seats.length; i++) {
            let seat = seats[i];
            let presale = 0;
            let price = seat.typeTicket.price.totalPrice;
            if (checkedPresale){
                price = seat.typeTicket.price.grossPrice;
            }
            const floatTotal = parseFloat(price).toFixed(2);
            let v1 = total*100;
            let v2 = floatTotal*100;
            let totalV1V2 = (v1 + v2 + presale)/100;
            total = parseFloat(totalV1V2.toString()).toFixed(2);
        }
    }
    return total;
};

export const returnArrTicket = (status, order, event, arrSeats, hasMap) =>{

    let arrTicket = [];
    let arr = {};

    if (event.type.toString() === "event"){
        arr["eventReplicaId"] = arrSeats[0].typeTicket.eventId.toString();
    }else{
        arr["eventReplicaId"] = arrSeats[0].typeTicket.carnetId.toString();
    }
    arr["presaleForced"] = false;
    arr["paymentMethod"] = "stripe";

    for (let i = 0; i < arrSeats.length; i++) {
        let objSeat = arrSeats[i];
        let obj = {};
        obj["type"] = "orderItem";
        obj["eventItemId"] = objSeat.typeTicket.id.toString();
        if (hasMap){
            obj["seatId"] = objSeat.seatGuid.toString();
        }
        arrTicket.push(obj);
    }
    arr["items"] = arrTicket;
    arr["status"] = status;

    if(order !== null) {
        if (typeof (order.customerData) !== 'undefined' && order.customerData !== null) {
            let objCustomerData = {};
            /*if (typeof (order.customerData.firstName) !== 'undefined' && order.customerData.firstName !== null && order.customerData.firstName.toString() !== "") {
                objCustomerData["firstName"] = order.customerData.firstName.toString();
            }
            if (typeof (order.customerData.lastName) !== 'undefined' && order.customerData.lastName !== null && order.customerData.lastName.toString() !== "") {
                objCustomerData["lastName"] = order.customerData.lastName.toString();
            }
            if (typeof (order.customerData.email) !== 'undefined' && order.customerData.email !== null && order.customerData.email.toString() !== "") {
                objCustomerData["email"] = order.customerData.email.toString();
            }
            if (typeof (order.customerData.phone) !== 'undefined' && order.customerData.phone !== null && order.customerData.phone.toString() !== "") {
                objCustomerData["phone"] = order.customerData.phone.toString();
            }
            if (typeof (order.customerData.mobile) !== 'undefined' && order.customerData.mobile !== null && order.customerData.mobile.toString() !== "") {
                objCustomerData["mobile"] = order.customerData.mobile.toString();
            }
            if (typeof (order.customerData.dateOfBirth) !== 'undefined' && order.customerData.dateOfBirth !== null && order.customerData.dateOfBirth.toString() !== "") {
                objCustomerData["dateOfBirth"] = order.customerData.dateOfBirth.toString();
            }
            if (typeof (order.customerData.placeOfBirth) !== 'undefined' && order.customerData.placeOfBirth !== null && order.customerData.placeOfBirth.toString() !== "") {
                objCustomerData["placeOfBirth"] = order.customerData.placeOfBirth.toString();
            }
            if (typeof (order.customerData.fiscalCode) !== 'undefined' && order.customerData.fiscalCode !== null && order.customerData.fiscalCode.toString() !== "") {
                objCustomerData["fiscalCode"] = order.customerData.fiscalCode.toString();
            }
            if (typeof (order.customerData.nationality) !== 'undefined' && order.customerData.nationality !== null && order.customerData.nationality.toString() !== "") {
                objCustomerData["nationality"] = order.customerData.nationality.toString();
            }
            if (typeof (order.customerData.gender) !== 'undefined' && order.customerData.gender !== null && order.customerData.gender.toString() !== "") {
                objCustomerData["gender"] = order.customerData.gender.toString();
            }
            if (typeof (order.customerData.note) !== 'undefined' && order.customerData.note !== null && order.customerData.note.toString() !== "") {
                objCustomerData["note"] = order.customerData.note.toString();
            }
            if (typeof (order.customerData.municipality) !== 'undefined' && order.customerData.municipality !== null && order.customerData.municipality.toString() !== "") {
                objCustomerData["municipality"] = order.customerData.municipality.toString();
            }
            if (typeof (order.customerData.postalCode) !== 'undefined' && order.customerData.postalCode !== null && order.customerData.postalCode.toString() !== "") {
                objCustomerData["postalCode"] = order.customerData.postalCode.toString();
            }
            if (typeof (order.customerData.name) !== 'undefined' && order.customerData.name !== null && order.customerData.name.toString() !== "") {
                objCustomerData["name"] = order.customerData.name.toString();
            }
            if (typeof (order.customerData.qualifier) !== 'undefined' && order.customerData.qualifier !== null && order.customerData.qualifier.toString() !== "") {
                objCustomerData["qualifier"] = order.customerData.qualifier.toString();
            }
            if (typeof (order.customerData.nation) !== 'undefined' && order.customerData.nation !== null && order.customerData.nation.toString() !== "") {
                objCustomerData["nation"] = order.customerData.nation.toString();
            }*/
            objCustomerData["id"] = order.customerData.id.toString();
            arr["customerData"] = objCustomerData;
        }
    }

    return arr;
}


export const renderSeatsSelected = (seats) => {
    if (seats.length > 0){
        let arr = seats.slice().reverse();
        return <div>
            <div className="divider" />
            {arr.map((seat) => (
                <Row className="justify-content-md-center row">
                    <Col className="col" screen_size={6}>
                        <div className='align-two-text-flex'>
                            <p>{seat.category}</p>
                        </div>
                        <div className='align-two-text-flex'>
                            <p className="title1-modal-event">{t("row")}&nbsp;<p className="title2-modal-event">{seat.row_number}</p></p>
                            <p className="title1-modal-event">{t("seat")}&nbsp;<p className="title2-modal-event">{seat.seatNumber}</p></p>
                        </div>
                        <div className="divider" />
                    </Col>
                </Row>
            ))}
        </div>
    }
    return null;
};

export const renderSeatsSelectedWithoutMap = (seats, arrItems) => {
    if (seats.length > 0){
        let arr = arrItems.slice().reverse();
        return <div>
            <div className="divider" />
            {arr.map((obj) => (
                <div>
                    {
                        seats.filter(z => z.code.toString() === obj.seatingOrder.toString() && z.typeTicket.titleType.toString() === obj.titleType.toString()).length > 0 ?
                            <Row className="justify-content-md-center row">
                                <Col className="col" screen_size={6}>
                                    <div className='align-two-text-flex'>
                                        <p>{typeof (seats.find(z => z.code.toString() === obj.seatingOrder.toString())) !== 'undefined' && seats.find(z => z.code.toString() === obj.seatingOrder.toString()) !== null ? seats.find(z => z.code.toString() === obj.seatingOrder.toString()).name.it.toString() + " (" + obj.titleType.toString() + ")" : ""}</p>
                                    </div>
                                    <div className="header-list-group-typeticket">
                                        <div
                                            className="list-group-typeticket-right">
                                            <p className="title2-modal-event">{seats.filter(z => z.code.toString() === obj.seatingOrder.toString() && z.typeTicket.titleType.toString() === obj.titleType.toString()).length}</p>
                                        </div>
                                        <div className="list-group-typeticket-left">
                                            <p className="title1-modal-event">{t("amount")}</p>
                                        </div>
                                    </div>
                                    <div className="divider"/>
                                </Col>
                            </Row> : null

                    }
                </div>
            ))}
        </div>
    }
    return null;
};

export const calculateTotalWithoutMap = (seats, checkedPresale) => {
    let total = 0;
    if(seats.length > 0){
        for (let i=0; i < seats.length; i++) {
            let seat = seats[i];
            let presale = 0;
            let price = seat.typeTicket.price.totalPrice;
            if (checkedPresale){
                price = seat.typeTicket.price.grossPrice;
            }
            const floatTotal = parseFloat(price).toFixed(2);
            let v1 = total*100;
            let v2 = floatTotal*100;
            let totalV1V2 = (v1 + v2 + presale)/100;
            total = parseFloat(totalV1V2.toString()).toFixed(2);
        }
    }
    return total;
};

export const renderTotalPriceByTypeTicket = (arrSeats, item, obj) => {
    let totItems = 0;
    const arr = arrSeats.filter(z => z.code.toString() === obj.code.toString() && z.typeTicket.titleType.toString() === item.titleType.toString());
    arr.forEach((item, key) => {
        let v1 = totItems * 100;
        let v2 = item.typeTicket.price.totalPrice.toString() * 100;
        let totalV1V2 = (Number(v1) + Number(v2));
        let tot = totalV1V2 / 100;
        totItems = parseFloat(tot).toFixed(2);
    });
    return <div className="text-center-total"><strong>{t("total")}</strong> : {totItems} €</div>
};

export const renderTotalByTypeTicket = (arrSeats, item, obj) => {
    let total = 0;
    const arr = arrSeats.filter(z => z.code.toString() === obj.code.toString() && z.typeTicket.titleType.toString() === item.titleType.toString());
    if (arr.length > 0) {
        total = arr.length;
    }
    return <div>{total.toString()}</div>
}

